// src/Page/MainboardListed.js

import React, { useState, useEffect } from "react";
import { Table, Row, Col } from "react-bootstrap";
import axios from "axios";
import { Helmet } from "react-helmet";
import Loader from "../Component/Loader";
import nodata from "../asset/images/ListingSoon.webp";
import { Link } from "react-router-dom";
import MainboardListed_Fetureimg from "../asset/images/MainboardListed_Fetureimg.jpg";

const MainboardListed = () => {
  const [loading, setLoading] = useState(true);
  const [listedMainLineIpos, setlistedMainLineIpos] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Start loading
      try {
        const mainlistedResponse = await axios.post(
          "https://app.ipodekho.com/GetMainLineIpo",
          {
            CategoryForIPOS: "MainlineIPO",
            type: "Listed",
          }
        );
        setlistedMainLineIpos(mainlistedResponse.data.listedIPO);
        setLoading(false); // Stop loading
      } catch (error) {
        console.error("Error fetching IPO data:", error);
        setLoading(false); // Stop loading even if there is an error
      }
    };
    fetchData();
  }, []);
  function formatDateRange(openDate, closeDate) {
    const options = { day: "numeric", month: "short", year: "numeric" };
    const open = new Date(openDate).toLocaleDateString(undefined, options);
    const close = new Date(closeDate).toLocaleDateString(undefined, options);
    return `${open} to ${close}`;
  }

  function formatDate(adddate) {
    const options = { day: "numeric", month: "short", year: "numeric" };
    const date = new Date(adddate).toLocaleDateString(undefined, options);
    return `${date}`;
  }

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // You can adjust this number based on your requirements

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = listedMainLineIpos.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0); // Scroll to the top
  };

  return (
    <>
      <Helmet>
        <title>Mainboard Listed IPOs - IPO DEKHO</title>
        <meta
          name="description"
          content="Welcome to the Mainboard Listed IPOs section on IPO DEKHO, your go-to platform for the latest updates and information on active initial public offerings. This page provides comprehensive details on all current IPOs listed on the mainboard, helping investors make informed decisions."
        />
        <meta
          property="og:url"
          content="https://ipodekho.com/mainboard-listed-ipos"
        />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Mainboard Listed IPOs - IPO DEKHO" />
        <meta
          property="og:description"
          content="Welcome to the Mainboard Listed IPOs section on IPO DEKHO, your go-to platform for the latest updates and information on active initial public offerings. This page provides comprehensive details on all current IPOs listed on the mainboard, helping investors make informed decisions."
        />
        <meta property="og:image" content={MainboardListed_Fetureimg} />

        <meta name="twitter:card" content="summary_large_image" />
        <meta
          property="twitter:domain"
          content="https://ipodekho.com/mainboard-listed-ipos"
        />
        <meta
          property="twitter:url"
          content="https://ipodekho.com/mainboard-listed-ipos"
        />
        <meta
          name="twitter:title"
          content="Mainboard Listed IPOs - IPO DEKHO"
        />
        <meta
          name="twitter:description"
          content="Welcome to the Mainboard Listed IPOs section on IPO DEKHO, your go-to platform for the latest updates and information on active initial public offerings. This page provides comprehensive details on all current IPOs listed on the mainboard, helping investors make informed decisions."
        />
        <meta name="twitter:image" content={MainboardListed_Fetureimg} />

        <link rel="canonical" href="https://ipodekho.com/mainboard-listed-ipos" />
      </Helmet>
      <section className="ipo-detail">
        <div className="container">
          <div className="section_title">
            <h2>
              <span>Mainboard Listed </span> IPOs
            </h2>
            <p>
              Mainboard listed IPOs feature established companies that have
              recently gone public on major stock exchanges. Investors monitor
              these listings to buy shares at their initial offering prices.
              These IPOs usually involve well-known firms with strong
              financials, offering significant market impact and potential for
              promising long-term returns.
            </p>
          </div>
          <div className="ipo_table_div">
            <div className="d-none d-md-block">
              {loading ? (
                <div className="text-center">
                  <Loader />
                </div>
              ) : (
                <Table bordered className="ipo_table">
                  {currentItems.length === 0 ? (
                    <div>
                      <center>
                        <img src={nodata} class="img-fluid" alt="sectionbg" />
                      </center>
                    </div>
                  ) : (
                    <>
                      <thead>
                        <tr>
                          <th colSpan="2">IPO Name</th>
                          <th>Offer Date</th>
                          <th>Offer Price</th>
                          <th>Lot Size</th>
                          <th>Subs</th>
                          <th colSpan="2">Listing Price</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentItems.map((ipo, index) => (
                          <tr key={ipo.id}>
                            <td className="ipo-image">
                              <img src={ipo.file} alt={ipo.companyName} />
                            </td>
                            <td className="ipo-name">
                              <span
                                className={`status ${ipo.IPOStatus.toLowerCase()}`}
                              >
                                {ipo?.IPOStatus === "WaitingAllotment"
                                  ? "Waiting Allotment"
                                  : null}
                                {ipo?.IPOStatus === "AllotmentOut"
                                  ? " Allotment Out"
                                  : null}
                                {ipo?.IPOStatus === "AllotmentToday"
                                  ? "Allotment Today"
                                  : null}
                                {ipo?.IPOStatus === "Live" ? "Live" : null}
                                {ipo?.IPOStatus === "Upcoming"
                                  ? "Upcoming"
                                  : null}
                                {ipo?.IPOStatus === "Listed" ? "Listed" : null}
                              </span>
                              <Link
                                className="ipo_name btn btn-primary"
                                to={`/ipo/${ipo.slug}`}
                              >
                                <strong>{ipo.companyName}</strong>
                              </Link>
                            </td>
                            <td className="ipo-date">
                              {formatDateRange(
                                ipo.IPOOpenDate,
                                ipo.IPOCloseDate
                              )}
                            </td>
                            <td className="ipo-price">
                              ₹{ipo.fromPrice} to ₹{ipo.toPrice}
                            </td>
                            <td className="ipo-lot">{ipo.lotSize} Shares</td>
                            <td className="ipo-subs">{ipo.total} x</td>
                            <td
                              className={
                                ipo?.listingPrice - ipo?.toPrice < 0
                                ? "listingPricefalse"
                                : ipo?.listingPrice - ipo?.toPrice >
                                  0
                                ? "listingPricetrue"
                                : "listingPriceneutral"
                              }
                            >
                              ₹{ipo?.listingPrice} (
                              {(
                                (ipo?.listingPrice * 100) / ipo?.toPrice -
                                100
                              ).toFixed(2)}
                              %)
                            </td>
                            <td className="ipo-link">
                              <Link
                                className="btn btn-primary"
                                to={`/ipo/${ipo.slug}`}
                              >
                                View
                              </Link>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </>
                  )}
                </Table>
              )}
            </div>
            <div className="d-block d-md-none">
              {loading ? (
                <div className="text-center">
                  <Loader />
                </div>
              ) : (
                <>
                  {currentItems.length === 0 ? (
                    <div>
                      <center>
                        <img src={nodata} class="img-fluid" alt="sectionbg" />
                      </center>
                    </div>
                  ) : (
                    <>
                      <div className="mobile_ipo text-start">
                        {currentItems.map((ipo, index) => (
                          <Link
                            className="mobile_card"
                            to={`/ipo/${ipo.slug}`}
                            key={ipo.id}
                          >
                            <span
                              className={`status ${ipo.IPOStatus.toLowerCase()}`}
                            >
                              {ipo?.IPOStatus === "WaitingAllotment"
                                ? "Waiting Allotment"
                                : null}
                              {ipo?.IPOStatus === "AllotmentOut"
                                ? " Allotment Out"
                                : null}
                              {ipo?.IPOStatus === "AllotmentToday"
                                ? "Allotment Today"
                                : null}
                              {ipo?.IPOStatus === "Live" ? "Live" : null}
                              {ipo?.IPOStatus === "Upcoming"
                                ? "Upcoming"
                                : null}
                              {ipo?.IPOStatus === "Listed" ? "Listed" : null}
                            </span>
                            <Row className="align-items-center">
                              <Col xs={4}>
                                <img
                                  src={ipo.file}
                                  alt={ipo.companyName}
                                  className="img-fluid ipo_company_logo"
                                />
                              </Col>
                              <Col xs={8}>
                                <h6 className="mb-0 ipo_companyName">
                                  {ipo.companyName}
                                </h6>
                              </Col>
                            </Row>
                            <Row className="top_border">
                              <Col xs={8} className="border_right">
                                <p className="small card_title mb-0">
                                  Offer Date
                                </p>
                                <p className="mb-0 card_info fw-medium">
                                  {formatDateRange(
                                    ipo.IPOOpenDate,
                                    ipo.IPOCloseDate
                                  )}
                                </p>
                              </Col>
                              <Col xs={4}>
                                <p className="small card_title mb-0">
                                  Offer Price
                                </p>
                                <p className="mb-0 card_info fw-medium">
                                  ₹{ipo.fromPrice} to ₹{ipo.toPrice}
                                </p>
                              </Col>
                            </Row>
                            <Row className="top_border">
                              <Col xs={7} className="border_right">
                                <p className="small card_title mb-0">
                                  Listing Date
                                </p>
                                <p className="mb-0 card_info fw-medium">
                                  {formatDate(ipo?.listingDate)}
                                </p>
                              </Col>
                              <Col xs={5}>
                                <p className="small card_title mb-0">
                                  Listing Price
                                </p>
                                <strong
                                  className={
                                    ipo?.listingPrice - ipo?.toPrice < 0
                                      ? "listingPricefalse"
                                      : "listingPricetrue"
                                  }
                                >
                                  ₹{ipo?.listingPrice} (
                                  {(
                                    (ipo?.listingPrice * 100) / ipo?.toPrice -
                                    100
                                  ).toFixed(2)}
                                  %)
                                </strong>
                              </Col>
                            </Row>
                            <Row className="top_border">
                              <Col>
                                <p className="ipo_card_link mb-0">
                                  View More Information
                                </p>
                              </Col>
                            </Row>
                          </Link>
                        ))}
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
          <div className="pagination ipo_pagination">
            {Array.from(
              { length: Math.ceil(listedMainLineIpos.length / itemsPerPage) },
              (_, index) => (
                <button
                  key={index + 1}
                  onClick={() => paginate(index + 1)}
                  className={`page-item ${
                    currentPage === index + 1 ? "active" : ""
                  }`}
                >
                  {index + 1}
                </button>
              )
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default MainboardListed;
