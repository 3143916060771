import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Container, Row, Col, Button, Table } from "react-bootstrap";
import axios from "axios";
import { Link } from "react-scroll";
import Loader from "../Component/Loader";
import { Helmet } from "react-helmet";

function IPODetail() {
  const { slug } = useParams();
  //const id = location.state.id;
  // console.log("id joi che???",props , "params" , ID)

  const [ipoDetail, setIpoDetail] = useState(null);
  const lastElementIndex = ipoDetail?.financialLotsize.length - 1;
  const lastElementofFinancials = ipoDetail?.companyFinancials.length - 1;

  const [addClassOnScroll, setAddClassOnScroll] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    // Adjust the scroll threshold as needed
    if (window.pageYOffset > 100) {
      setAddClassOnScroll(true);
    } else {
      setAddClassOnScroll(false);
    }
  };

  useEffect(() => {
    const fetchIpoDetail = async () => {
      try {
        const response = await axios.post(
          `https://app.ipodekho.com/GetSlugByMainLineIpo/${slug}`
        );
        setIpoDetail(response.data.Data); // Use response.data.Data instead of response.data
        // console.log(response.data.Data);
      } catch (error) {
        console.error("Error fetching IPO detail:", error);
      }
    };

    fetchIpoDetail();
  }, [slug]);

  if (!ipoDetail) {
    return <Loader />;
  }

  function formatDateRange(openDate, closeDate) {
    const options = { day: "numeric", month: "short", year: "numeric" };
    const open = new Date(openDate).toLocaleDateString(undefined, options);
    const close = new Date(closeDate).toLocaleDateString(undefined, options);
    return `${open} to ${close}`;
  }

  function formatDate(adddate) {
    const options = { day: "numeric", month: "short", year: "numeric" };
    const date = new Date(adddate).toLocaleDateString(undefined, options);
    return `${date}`;
  }

  function formatDateTime(subscriptionDateAndTime) {
    const options = { day: "numeric", month: "short", year: "numeric" };
    const formattedDate = new Date(subscriptionDateAndTime).toLocaleDateString(
      undefined,
      options
    );

    const options1 = {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: "true",
    };
    const formattedTime = new Date(subscriptionDateAndTime).toLocaleTimeString(
      undefined,
      options1
    );
    return `${formattedDate} ${formattedTime}`;
  }

  const SubscriptionTable = ({ subscriptionDetails }) => {
    return (
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Date</th>
            {ipoDetail?.CategoryForIPOS !== "SmeIPO" && <th>QIBs</th>}
            <th>HNI</th>
            <th>Retail</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          {subscriptionDetails.map((subscription, index) => (
            <tr key={index}>
              <td>
                {new Date(subscription.Date).toLocaleDateString("en-US", {
                  month: "short",
                  day: "2-digit",
                  year: "numeric",
                })}
              </td>
              {ipoDetail?.CategoryForIPOS !== "SmeIPO" && (
                <td>
                  {subscription.QIB !== "" ? `${subscription.QIB}x` : "-"}
                </td>
              )}
              <td>{subscription.NII || "-"}x</td>
              <td>{subscription.Retail || "-"}x</td>
              <td>{subscription.Total || "-"}x</td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  };

  return (
    <section className="ipo-detail">
      <Helmet>
        {/* <title>{`${ipoDetail?.metaTtile ||`${ipoDetail?.companyName} IPO: Key Dates, Price, GMP & Insights`} - IPO DEKHO`}
        </title>
        {ipoDetail?.metaDescription ? (
          <meta property="og:description" content={ipoDetail?.metaDescription} />
        ) : (
          <meta property="og:description" content={`Check ${ipoDetail?.companyName} IPO: Tentative Timetable, Price, Live Subscription, Allotment, GMP, Listing Date, Company Financials, and more details.`}/>)}
        <meta property="og:title" content={`${ipoDetail?.metaTtile ||`${ipoDetail?.companyName} IPO: Key Dates, Price, GMP & Insights`} - IPO DEKHO`}/>
        <meta property="og:image" content={ipoDetail?.metaImage} />
        <meta property="og:url" content={`${BASE_URL}/${ipoDetail?.slug}`} />
        <meta property="og:type" content="website" /> */}

        <title>{`${
          ipoDetail?.metaTtile ||
          `${ipoDetail?.companyName} IPO: Key Dates, Price, GMP & Insights`
        } - IPO DEKHO`}</title>
        <meta
          name="description"
          content="Check Stanley Lifestyles Limited IPO: Tentative Timetable, Price, Live Subscription, Allotment, GMP, Listing Date, Company Financials, and more details."
        />
        {ipoDetail?.metaDescription ? (
          <meta name="description" content={ipoDetail?.metaDescription} />
        ) : (
          <meta
            name="description"
            content={`Check ${ipoDetail?.companyName} IPO: Tentative Timetable, Price, Live Subscription, Allotment, GMP, Listing Date, Company Financials, and more details.`}
          />
        )}
        <meta
          property="og:url"
          content={`https://ipodekho.com/${ipoDetail?.slug}`}
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content={`${
            ipoDetail?.metaTtile ||
            `${ipoDetail?.companyName} IPO: Key Dates, Price, GMP & Insights`
          } - IPO DEKHO`}
        />
        {ipoDetail?.metaDescription ? (
          <meta
            property="og:description"
            content={ipoDetail?.metaDescription}
          />
        ) : (
          <meta
            property="og:description"
            content={`Check ${ipoDetail?.companyName} IPO: Tentative Timetable, Price, Live Subscription, Allotment, GMP, Listing Date, Company Financials, and more details.`}
          />
        )}
        <meta property="og:image" content={ipoDetail?.metaImage} />

        {/* Twitter Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="ipodekho.com" />
        <meta
          property="twitter:url"
          content={`https://ipodekho.com/${ipoDetail?.slug}`}
        />
        <meta
          name="twitter:title"
          content={`${
            ipoDetail?.metaTtile ||
            `${ipoDetail?.companyName} IPO: Key Dates, Price, GMP & Insights`
          } - IPO DEKHO`}
        />
        {ipoDetail?.metaDescription ? (
          <meta
            property="twitter:description"
            content={ipoDetail?.metaDescription}
          />
        ) : (
          <meta
            property="twitter:description"
            content={`Check ${ipoDetail?.companyName} IPO: Tentative Timetable, Price, Live Subscription, Allotment, GMP, Listing Date, Company Financials, and more details.`}
          />
        )}
        <meta name="twitter:image" content={ipoDetail?.metaImage} />
      </Helmet>
      <Container>
        <div className="single-ipo-detail">
          <Row>
            <Col xs={12}>
              <div
                className={`table-of-contents-div_scrolling ${
                  addClassOnScroll ? "toc_scrolling" : ""
                }`}
              >
                <ul className="table-of-contents-div-ul">
                  {ipoDetail?.IPOstatus === "Live" && (
                    <li>
                      <Link
                        to="livesubscription"
                        smooth={true}
                        duration={100}
                        offset={-100}
                      >
                        Live Subscription
                      </Link>
                    </li>
                  )}
                  {ipoDetail?.subscriptionDetails &&
                    ipoDetail?.subscriptionDetails.length > 0 && (
                      <li>
                        <Link
                          to="subscriptiondetails"
                          smooth={true}
                          duration={100}
                          offset={-100}
                        >
                          Subscription Details
                        </Link>
                      </li>
                    )}
                  <li>
                    <Link
                      to="tentativetimetable"
                      smooth={true}
                      duration={100}
                      offset={-100}
                    >
                      Tentative Timetable
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="ipodetails"
                      smooth={true}
                      duration={100}
                      offset={-100}
                    >
                      IPO Details
                    </Link>
                  </li>
                  {ipoDetail?.Strength?.length > 0 &&
                    ipoDetail?.Risk?.length > 0 &&
                    ipoDetail?.CategoryForIPOS === "MainlineIPO" && (
                      <li>
                        <Link
                          to="keypoint"
                          smooth={true}
                          duration={100}
                          offset={-100}
                        >
                          Strengths & Risks
                        </Link>
                      </li>
                    )}
                  {/* {ipoDetail?.ObjectOfIssue && (
                    <li>
                      <Link to="objectsissue" smooth={true} duration={100} offset={-100}>
                        Objects Of the Issue
                      </Link>
                    </li>
                  )} */}
                  {/* <li>
                    <Link to="promotersholdings" smooth={true} duration={100} offset={-100}>
                      Promoters Holdings
                    </Link>
                  </li> */}
                  {ipoDetail?.promotersName &&
                    ipoDetail?.promotersName.length > 0 && (
                      <li>
                        <Link
                          to="promoters"
                          smooth={true}
                          duration={100}
                          offset={-100}
                        >
                          Promoters
                        </Link>
                      </li>
                    )}
                  <li>
                    <Link
                      to="lotsize"
                      smooth={true}
                      duration={100}
                      offset={-100}
                    >
                      Lot Size
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="companyfinancials"
                      smooth={true}
                      duration={100}
                      offset={-100}
                    >
                      Company Financials
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="registrar"
                      smooth={true}
                      duration={100}
                      offset={-100}
                    >
                      Registrar
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="leadmanagers"
                      smooth={true}
                      duration={100}
                      offset={-100}
                    >
                      Lead Managers
                    </Link>
                  </li>
                  <li>
                    <Link to="about" smooth={true} duration={100} offset={-100}>
                      About
                    </Link>
                  </li>
                </ul>
              </div>
            </Col>
            <Col xs={12} lg={9}>
              <div className="single-ipo-detail-inn">
                <div className="single-ipo-detail-inn-first-section">
                  <Row className="align-items-center">
                    <Col
                      xs={12}
                      xl={5}
                      md={12}
                      className="image-title-status-col"
                    >
                      <div className="single-ipo-detail-image">
                        <img
                          src={ipoDetail?.file}
                          alt={ipoDetail?.companyName}
                        />
                      </div>
                      <div className="single-ipo-detail-title">
                        <h1>{ipoDetail?.companyName}</h1>
                      </div>
                      <div className="single-ipo-detail-status">
                        <span
                          className={`status ${ipoDetail?.IPOstatus.toLowerCase()}`}
                        >
                          {ipoDetail?.IPOstatus === "WaitingAllotment"
                            ? "Waiting Allotment"
                            : null}
                          {ipoDetail?.IPOstatus === "AllotmentOut"
                            ? " Allotment Out"
                            : null}
                          {ipoDetail?.IPOstatus === "AllotmentToday"
                            ? "Allotment Today"
                            : null}
                          {ipoDetail?.IPOstatus === "Live" ? "Live" : null}
                          {ipoDetail?.IPOstatus === "Upcoming"
                            ? "Upcoming"
                            : null}
                          {ipoDetail?.IPOstatus === "Listed" ? "Listed" : null}
                        </span>
                      </div>
                    </Col>
                    <Col
                      xs={12}
                      xl={3}
                      md={6}
                      className="prem-listing-price-col"
                    >
                      {ipoDetail?.IPOstatus === "Listed" ? (
                        <div className="single-ipo-listing-price">
                          <span>Listing Price</span>
                          <strong
                            className={
                              ipoDetail?.listingPrice - ipoDetail?.toPrice < 0
                                ? "false"
                                : "true"
                            }
                          >
                            ₹{ipoDetail?.listingPrice} (
                            {(
                              (ipoDetail?.listingPrice * 100) /
                                ipoDetail?.toPrice -
                              100
                            ).toFixed(2)}
                            %)
                          </strong>
                        </div>
                      ) : (
                        <div className={`single-ipo-premium-price`}>
                          <span>Premium</span>
                          <strong
                            className={
                              ipoDetail?.GMP < 0
                                ? "false"
                                : ipoDetail?.GMP > 0
                                ? "true"
                                : "neutral"
                            }
                          >
                            {isNaN(ipoDetail?.GMP) ? "-" : `₹${ipoDetail?.GMP}`}{" "}
                            {isNaN(ipoDetail?.GMP) || isNaN(ipoDetail?.toPrice)
                              ? ""
                              : `(${(
                                  (ipoDetail?.GMP * 100) /
                                  ipoDetail?.toPrice
                                ).toFixed(2)}%)`}
                          </strong>
                        </div>
                      )}
                    </Col>
                    <Col xs={12} xl={4} md={6} className="lot-size-buttons-col">
                      <div className="lot-size">
                        <p>
                          <em>
                            ₹
                            {(
                              ipoDetail?.lotSize * ipoDetail?.toPrice
                            ).toLocaleString("en-IN")}
                          </em>
                          /{ipoDetail?.lotSize} Shares
                          <br />
                          Minimum Investment
                        </p>
                      </div>
                      <div className="buttons-col">
                        {ipoDetail?.IPOstatus === "AllotmentOut" && (
                          <div className="check-alot-div">
                            <Button
                              href={ipoDetail?.checkAllotment}
                              target="_blank"
                              className="mt-2 btn-ipo check-alot"
                            >
                              Check Allotment
                            </Button>
                          </div>
                        )}
                        {ipoDetail?.IPOstatus === "Live" && (
                          <div className="check-live-div">
                            <Button
                              href={ipoDetail?.checkLiveSubscriptionUrl}
                              target="_blank"
                              className="mt-2 btn-ipo check-live"
                            >
                              Check Live Subscription
                            </Button>
                          </div>
                        )}
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="single-ipo-detail-inn-second-section">
                  <Row>
                    <Col xs={6} xl={3} md={6}>
                      <div className="date-offer-lots-subs date-div">
                        <span>Offer Date</span>
                        <strong>
                          {formatDateRange(
                            ipoDetail?.IPOOpenDate,
                            ipoDetail?.IPOCloseDate
                          )}
                        </strong>
                      </div>
                    </Col>
                    <Col xs={6} xl={3} md={6}>
                      <div className="date-offer-lots-subs offer-div">
                        <span>Offer Price</span>
                        <strong>
                          ₹{ipoDetail?.fromPrice} to ₹{ipoDetail?.toPrice}
                        </strong>
                      </div>
                    </Col>
                    <Col xs={6} xl={3} md={6}>
                      <div className="date-offer-lots-subs lot-div">
                        <span>Lots Shares</span>
                        <strong>{ipoDetail?.lotSize} Share</strong>
                      </div>
                    </Col>
                    <Col xs={6} xl={3} md={6}>
                      <div className="date-offer-lots-subs subs-div">
                        <span>Subs</span>
                        <strong>{ipoDetail?.total} Times</strong>
                      </div>
                    </Col>
                  </Row>
                </div>
                {ipoDetail?.IPOstatus === "Live" && (
                  <div
                    className="single-ipo-detail-inn-live-subs-section"
                    id="livesubscription"
                  >
                    <div className="section-title">
                      <h2>{ipoDetail?.companyName} Live Subscription</h2>
                    </div>
                    <div className="section-text">
                      <p>
                        {ipoDetail?.companyName} IPO live subscription till{" "}
                        {ipoDetail?.subscriptionDateAndTime != null &&
                        ipoDetail?.subscriptionDateAndTime !== ""
                          ? `${formatDateTime(
                              ipoDetail?.subscriptionDateAndTime
                            )}`
                          : "-"}{" "}
                        as QIB quota{" "}
                        {ipoDetail?.qualifiedInstitutions != null &&
                        ipoDetail?.qualifiedInstitutions !== ""
                          ? `${ipoDetail?.qualifiedInstitutions}x`
                          : "-"}
                        , HNI quota {ipoDetail?.nonInstitutionalBuyers}x and
                        Retail quota {ipoDetail?.retailInvestors}x with the
                        total subscription of {ipoDetail?.total} Times.
                      </p>
                    </div>
                    <div className="section-live-sub-detail single-ipo-detail-table ">
                      <Row>
                        <Col>
                          <Table striped bordered hover>
                            <thead>
                              <tr>
                                <th>Date</th>
                                {ipoDetail?.CategoryForIPOS !== "SmeIPO" && (
                                  <th>QIBs</th>
                                )}
                                <th className="hni_th">HNI</th>
                                <th>Retail</th>
                                <th>Total</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  {ipoDetail?.subscriptionDateAndTime != null &&
                                  ipoDetail?.subscriptionDateAndTime !== ""
                                    ? `${formatDateTime(
                                        ipoDetail?.subscriptionDateAndTime
                                      )}`
                                    : "-"}
                                </td>
                                {ipoDetail?.CategoryForIPOS !== "SmeIPO" && (
                                  <td>
                                    {ipoDetail?.qualifiedInstitutions != null &&
                                    ipoDetail?.qualifiedInstitutions !== ""
                                      ? `${ipoDetail?.qualifiedInstitutions}x`
                                      : "-"}
                                  </td>
                                )}
                                <td className="hni_th">
                                  <span className="d-none d-sm-inline-block">
                                    Bids
                                  </span>{" "}
                                  Above ₹10L :{" "}
                                  {ipoDetail?.bNII != null &&
                                  ipoDetail?.bNII !== ""
                                    ? `${ipoDetail?.bNII}x`
                                    : "-"}
                                  <br />
                                  <span className="d-none d-sm-inline-block">
                                    Bids
                                  </span>{" "}
                                  Below ₹10L :{" "}
                                  {ipoDetail?.sNII != null &&
                                  ipoDetail?.sNII !== ""
                                    ? `${ipoDetail?.sNII}x`
                                    : "-"}
                                  <br />
                                  NII Total :{" "}
                                  {ipoDetail?.nonInstitutionalBuyers != null &&
                                  ipoDetail?.nonInstitutionalBuyers !== ""
                                    ? `${ipoDetail?.nonInstitutionalBuyers}x`
                                    : "-"}
                                </td>
                                <td>
                                  {ipoDetail?.retailInvestors != null &&
                                  ipoDetail?.retailInvestors !== ""
                                    ? `${ipoDetail?.retailInvestors}x`
                                    : "-"}
                                </td>
                                <td>
                                  <strong>
                                    {ipoDetail?.total != null &&
                                    ipoDetail?.total !== ""
                                      ? `${ipoDetail?.total} Times`
                                      : "-"}
                                  </strong>
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </Col>
                      </Row>
                    </div>
                  </div>
                )}
                {ipoDetail?.subscriptionDetails &&
                ipoDetail?.subscriptionDetails.length > 0 ? (
                  <div
                    className="single-ipo-detail-inn-subs-detail-section"
                    id="subscriptiondetails"
                  >
                    <div className="section-title">
                      <h2>{ipoDetail?.companyName} Subscription Details</h2>
                    </div>
                    <div className="section-sub-detail single-ipo-detail-table">
                      <SubscriptionTable
                        subscriptionDetails={ipoDetail?.subscriptionDetails}
                      />
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <div
                  className="single-ipo-detail-inn-tentetiv-timetable-section"
                  id="tentativetimetable"
                >
                  <div className="section-title">
                    <h2>{ipoDetail?.companyName} IPO Tentative Timetable</h2>
                  </div>
                  <div className="section-text">
                    <p>
                      {ipoDetail?.companyName} IPO opens on{" "}
                      {formatDate(ipoDetail?.IPOOpenDate)}, and closes on{" "}
                      {formatDate(ipoDetail?.IPOCloseDate)}, with the listing
                      scheduled for {formatDate(ipoDetail?.listingDate)}.
                    </p>
                  </div>
                  <div className="section-tentetiv-timetable-detail">
                    <Row>
                      <Col xs={6} xl={4} lg={4} md={4}>
                        <div className="single-ipo-detail-title-text">
                          <span>Open Date</span>
                          <strong>{formatDate(ipoDetail?.IPOOpenDate)}</strong>
                        </div>
                      </Col>
                      <Col xs={6} xl={4} lg={4} md={4}>
                        <div className="single-ipo-detail-title-text">
                          <span>Close Date</span>
                          <strong>{formatDate(ipoDetail?.IPOCloseDate)}</strong>
                        </div>
                      </Col>
                      <Col xs={6} xl={4} lg={4} md={4}>
                        <div className="single-ipo-detail-title-text">
                          <span>Allotment Date</span>
                          <strong>
                            {formatDate(ipoDetail?.IPOAllotmentDate)}
                          </strong>
                        </div>
                      </Col>
                    </Row>
                    <hr />
                    <Row>
                      <Col xs={6} xl={4} lg={4} md={4}>
                        <div className="single-ipo-detail-title-text">
                          <span>Refunds Initiation</span>
                          <strong>
                            {formatDate(ipoDetail?.IPORefundsInitiation)}
                          </strong>
                        </div>
                      </Col>
                      <Col xs={6} xl={4} lg={4} md={4}>
                        <div className="single-ipo-detail-title-text">
                          <span>Demat Transfer</span>
                          <strong>
                            {formatDate(ipoDetail?.IPODematTransfer)}
                          </strong>
                        </div>
                      </Col>
                      <Col xs={6} xl={4} lg={4} md={4}>
                        <div className="single-ipo-detail-title-text">
                          <span>Listing Date</span>
                          <strong>{formatDate(ipoDetail?.listingDate)}</strong>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>

                <div
                  className="single-ipo-detail-inn-ipo-detail-section"
                  id="ipodetails"
                >
                  <div className="section-title">
                    <h2>{ipoDetail?.companyName} IPO Details</h2>
                  </div>
                  <div className="section-text">
                    <p>
                      {ipoDetail?.companyName} IPO has an issue size of Approx ₹
                      {ipoDetail?.issueSize} Crores, with a face value of ₹
                      {ipoDetail?.faceValue} per share and a price band ranging
                      from ₹{ipoDetail?.fromPrice} to ₹{ipoDetail?.toPrice} per
                      share.
                    </p>
                  </div>
                  <div className="section-ipo-detail-detail">
                    <Row>
                      <Col xs={6} xl={3}>
                        <div className="single-ipo-detail-title-text">
                          <span>Face Value</span>
                          <strong>₹{ipoDetail?.faceValue} Per Share</strong>
                        </div>
                      </Col>
                      <Col xs={6} xl={3}>
                        <div className="single-ipo-detail-title-text">
                          <span>Issue Price</span>
                          <strong>
                            ₹{ipoDetail?.fromPrice} to ₹{ipoDetail?.toPrice}
                          </strong>
                        </div>
                      </Col>
                      <Col xs={6} xl={3}>
                        <div className="single-ipo-detail-title-text">
                          <span>Lot Size</span>
                          <strong>{ipoDetail?.lotSize} Share</strong>
                        </div>
                      </Col>
                      <Col xs={6} xl={3}>
                        <div className="single-ipo-detail-title-text">
                          <span>Issue Size</span>
                          <strong>
                            {ipoDetail?.issueSize !== ""
                              ? `Approx ₹${ipoDetail?.issueSize} Crores`
                              : "-"}
                          </strong>
                        </div>
                      </Col>
                    </Row>
                    <hr />
                    <Row>
                      <Col xs={6} xl={3}>
                        <div className="single-ipo-detail-title-text">
                          <span>Fresh Issue</span>
                          <strong>
                            {ipoDetail?.freshIssue !== ""
                              ? `Approx ₹${ipoDetail?.freshIssue} Crores`
                              : "-"}
                          </strong>
                        </div>
                      </Col>
                      <Col xs={6} xl={3}>
                        <div className="single-ipo-detail-title-text">
                          <span>Offer For Sale</span>
                          <strong>
                            {ipoDetail?.offerForSale !== ""
                              ? `Approx ₹${ipoDetail?.offerForSale} Crores`
                              : "-"}
                          </strong>
                        </div>
                      </Col>
                      <Col xs={6} xl={3}>
                        <div className="single-ipo-detail-title-text">
                          <span>Retail Quota</span>
                          <strong>
                            {ipoDetail?.reatailQuota !== ""
                              ? `${ipoDetail?.reatailQuota}%`
                              : "-"}
                          </strong>
                        </div>
                      </Col>
                      <Col xs={6} xl={3}>
                        <div className="single-ipo-detail-title-text">
                          <span>QIB Quota</span>
                          <strong>
                            {ipoDetail?.qibQuota !== ""
                              ? `${ipoDetail?.qibQuota}%`
                              : "-"}
                          </strong>
                        </div>
                      </Col>
                    </Row>
                    <hr />
                    <Row>
                      <Col xs={6} xl={3}>
                        <div className="single-ipo-detail-title-text">
                          <span>HNI Quota</span>
                          <strong>
                            {ipoDetail?.nilQuota !== ""
                              ? `${ipoDetail?.nilQuota}%`
                              : "-"}
                          </strong>
                        </div>
                      </Col>
                      <Col xs={6} xl={3}>
                        <div className="single-ipo-detail-title-text">
                          <span>Retail App</span>
                          <strong>
                            {ipoDetail?.retailApp !== ""
                              ? `${ipoDetail?.retailApp?.toLocaleString(
                                  "en-IN"
                                )} Approx`
                              : "-"}
                          </strong>
                        </div>
                      </Col>
                      <Col xs={6} xl={3}>
                        <div className="single-ipo-detail-title-text">
                          <span>S-HNI App</span>
                          <strong>
                            {ipoDetail?.SHNI !== ""
                              ? `${ipoDetail?.SHNI?.toLocaleString(
                                  "en-IN"
                                )} Approx`
                              : "-"}
                          </strong>
                        </div>
                      </Col>
                      <Col xs={6} xl={3}>
                        <div className="single-ipo-detail-title-text">
                          <span>B-HNI App</span>
                          <strong>
                            {ipoDetail?.BHNI !== ""
                              ? `${ipoDetail?.BHNI?.toLocaleString(
                                  "en-IN"
                                )} Approx`
                              : "-"}
                          </strong>
                        </div>
                      </Col>
                    </Row>
                    <hr />
                    <Row>
                      <Col xs={6} xl={3}>
                        <div className="single-ipo-detail-title-text">
                          <span>Issue Type</span>
                          <strong>{ipoDetail?.issueType}</strong>
                        </div>
                      </Col>
                      <Col xs={6} xl={3}>
                        <div className="single-ipo-detail-title-text">
                          <span>Listing At</span>
                          <strong>{ipoDetail?.listingAt.join(", ")}</strong>
                        </div>
                      </Col>
                      <Col xs={6} xl={3}>
                        <div className="single-ipo-detail-title-text">
                          <span>Registrar</span>
                          <strong>{ipoDetail?.registerName}</strong>
                        </div>
                      </Col>
                      <Col xs={6} xl={3}></Col>
                    </Row>
                    <hr />
                    <div className="section-ipo-detail-detail-buttons">
                      {ipoDetail?.DRHPDraft && (
                        <Button
                          href={ipoDetail?.DRHPDraft}
                          className="btn-ipo pdf"
                          target="_blank"
                        >
                          DRHP Draft
                        </Button>
                      )}
                      {ipoDetail?.RHPDraft && (
                        <Button
                          href={ipoDetail?.RHPDraft}
                          className="btn-ipo pdf"
                          target="_blank"
                        >
                          RHP Draft
                        </Button>
                      )}
                      {ipoDetail?.AnchorInvestors && (
                        <Button
                          href={ipoDetail?.AnchorInvestors}
                          className="btn-ipo pdf"
                          target="_blank"
                        >
                          Anchor Investors List
                        </Button>
                      )}
                    </div>
                  </div>
                </div>

                {ipoDetail?.Strength?.length > 0 &&
                  ipoDetail?.Risk?.length > 0 &&
                  ipoDetail?.CategoryForIPOS === "MainlineIPO" && (
                    <div
                      className="single-ipo-detail-inn-key-point-section"
                      id="keypoint"
                    >
                      <div className="section-title">
                        <h2>{ipoDetail?.companyName} IPO Strengths & Risks</h2>
                      </div>
                      <div className="section-sub-detail-key-point">
                        <Row>
                          <Col className="col" xs={12} xl={6} lg={6} md={12}>
                            <div className="key-points-outer strengths">
                              <div className="key-point-title">
                                <h3>
                                  Strengths
                                  <span className="count">
                                    {ipoDetail?.Strength.length}
                                  </span>
                                </h3>
                              </div>
                              <div className="key-points-list">
                                {ipoDetail?.Strength.map((strength, index) => (
                                  <div className="key-point-item" key={index}>
                                    {strength}
                                  </div>
                                ))}
                              </div>
                            </div>
                          </Col>
                          <Col className="col" xs={12} xl={6} lg={6} md={12}>
                            <div className="key-points-outer risks">
                              <div className="key-point-title">
                                <h3>
                                  Risks
                                  <span className="count">
                                    {ipoDetail?.Risk.length}
                                  </span>
                                </h3>
                              </div>
                              <div className="key-points-list">
                                {ipoDetail?.Risk.map((risk, index) => (
                                  <div className="key-point-item" key={index}>
                                    {risk}
                                  </div>
                                ))}
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  )}

                {ipoDetail?.ObjectOfIssue && (
                  <div
                    className="single-ipo-detail-inn-obects-of-issue-section"
                    id="objectsissue"
                  >
                    <div className="section-title">
                      <h2>{ipoDetail?.companyName} IPO Objects Of the Issue</h2>
                    </div>
                    <div className="section-sub-detail-obects-of-issue">
                      <Row>
                        <Col>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: ipoDetail?.ObjectOfIssue,
                            }}
                          />
                        </Col>
                      </Row>
                    </div>
                  </div>
                )}

                <div
                  className="single-ipo-detail-inn-promoter-holding-section"
                  id="promotersholdings"
                >
                  <div className="section-title">
                    <h2>{ipoDetail?.companyName} Promoters Holdings</h2>
                  </div>
                  <div className="section-sub-detail single-ipo-detail-table">
                    <Table bordered>
                      <thead>
                        <tr>
                          <th>Pre Issue Share Holding</th>
                          <th>Post Issue Share Holding</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            {ipoDetail?.preIssueShareHolding !== ""
                              ? `${ipoDetail?.preIssueShareHolding} %`
                              : "-"}
                          </td>
                          <td>
                            {ipoDetail?.postIssueShareHolding !== ""
                              ? `${ipoDetail?.postIssueShareHolding} %`
                              : "-"}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </div>

                {ipoDetail?.promotersName &&
                  ipoDetail?.promotersName.length > 0 && (
                    <div
                      className="single-ipo-detail-inn-company-promoters-section"
                      id="promoters"
                    >
                      <div className="section-title">
                        <h2>{ipoDetail?.companyName} Promoters</h2>
                      </div>
                      <div className="section-sub-detail-company-promoters">
                        <ol>
                          {ipoDetail?.promotersName.map((promoter, index) => (
                            <li key={index}>{promoter.name}</li>
                          ))}
                        </ol>
                      </div>
                    </div>
                  )}

                <div
                  className="single-ipo-detail-inn-ipo-lot-size-section"
                  id="lotsize"
                >
                  <div className="section-title">
                    <h2>{ipoDetail?.companyName} IPO Lot Size</h2>
                  </div>
                  <div className="section-text">
                    <p>
                      The {ipoDetail?.companyName} IPO minimum market lot is{" "}
                      {ipoDetail?.lotSize} shares with ₹
                      {ipoDetail?.financialLotsize[0]?.amount.toLocaleString(
                        "en-IN"
                      )}{" "}
                      application amount. The retail investors can apply up-to{" "}
                      {ipoDetail?.financialLotsize[1]?.lots} lots with{" "}
                      {ipoDetail?.financialLotsize[1]?.shares} shares or ₹
                      {ipoDetail?.financialLotsize[1]?.amount.toLocaleString(
                        "en-IN"
                      )}{" "}
                      amount. The HNIs can apply up-to{" "}
                      {ipoDetail?.financialLotsize[lastElementIndex]?.lots} lots
                      with{" "}
                      {ipoDetail?.financialLotsize[lastElementIndex]?.shares}{" "}
                      shares or ₹
                      {ipoDetail?.financialLotsize[
                        lastElementIndex
                      ]?.amount.toLocaleString("en-IN")}{" "}
                      amount.
                    </p>
                  </div>
                  <div className="section-sub-detail single-ipo-detail-table">
                    <table>
                      <thead>
                        <tr>
                          <th>Application</th>
                          <th>Lots</th>
                          <th>Shares</th>
                          <th>Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {ipoDetail?.financialLotsize.map((lot, index) => (
                          <tr key={index}>
                            <td>{lot.application}</td>
                            <td>
                              {lot.lots} Lot{lot.lots > 1 ? "s" : ""}
                            </td>
                            <td>{lot.shares} Shares</td>
                            <td>₹{lot.amount.toLocaleString("en-IN")}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>

                <div
                  className="single-ipo-detail-inn-company-financials-section"
                  id="companyfinancials"
                >
                  <div className="section-title">
                    <h2>
                      {ipoDetail?.companyName} Company Financials (in{" "}
                      {ipoDetail?.CategoryForIPOS === "MainlineIPO"
                        ? "Crore"
                        : "Lakhs"}
                      )
                    </h2>
                  </div>
                  <div className="section-text">
                    <p>
                      As of{" "}
                      {formatDate(
                        ipoDetail?.companyFinancials[lastElementofFinancials]
                          ?.period
                      )}{" "}
                      , {ipoDetail?.companyName} company reported assets of{" "}
                      {ipoDetail?.companyFinancials[
                        lastElementofFinancials
                      ]?.assets.toLocaleString("en-IN")}{" "}
                      {ipoDetail?.CategoryForIPOS === "MainlineIPO"
                        ? "Crore"
                        : "Lakhs"}
                      , revenue of&nbsp;{" "}
                      {ipoDetail?.companyFinancials[
                        lastElementofFinancials
                      ]?.revenue.toLocaleString("en-IN")}{" "}
                      {ipoDetail?.CategoryForIPOS === "MainlineIPO"
                        ? "Crore"
                        : "Lakhs"}
                      , and a profit after tax of&nbsp;
                      {ipoDetail?.companyFinancials[
                        lastElementofFinancials
                      ]?.profit.toLocaleString("en-IN")}{" "}
                      {ipoDetail?.CategoryForIPOS === "MainlineIPO"
                        ? "Crore"
                        : "Lakhs"}
                      .
                    </p>
                  </div>
                  <div className="section-sub-detail single-ipo-detail-table">
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>Period</th>
                          <th>Assets</th>
                          <th>Revenue</th>
                          <th>Profit</th>
                        </tr>
                      </thead>
                      <tbody>
                        {ipoDetail?.companyFinancials.map(
                          (financial, index) => (
                            <tr key={index}>
                              <td>{formatDate(financial.period)}</td>
                              <td>
                                {financial.assets !== ""
                                  ? financial.assets.toFixed(2)
                                  : ""}
                              </td>
                              <td>
                                {financial.revenue !== ""
                                  ? financial.revenue.toFixed(2)
                                  : ""}
                              </td>
                              <td>
                                {financial.profit !== ""
                                  ? financial.profit.toFixed(2)
                                  : ""}
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </Table>
                  </div>

                  {ipoDetail?.peersComparison &&
                    ipoDetail?.peersComparison.length > 0 && (
                      <div className="single-ipo-detail-inn-peer-groups-section">
                        <div className="section-title">
                          <h2>{ipoDetail?.companyName} Peer Groups</h2>
                        </div>
                        <div className="section-sub-detail-peer-groups">
                          <ol>
                            {ipoDetail?.peersComparison.map((peer, index) => (
                              <li key={index}>{peer?.company_name}</li>
                            ))}
                          </ol>
                        </div>
                      </div>
                    )}
                </div>

                <div
                  className="single-ipo-detail-inn-ipo-registrar-section mt-4"
                  id="registrar"
                >
                  <div className="section-title">
                    <h2>{ipoDetail?.companyName} IPO Registrar</h2>
                  </div>
                  <div className="section-sub-detail-ipo-registrar mt-3">
                    <p>{ipoDetail?.registerName}</p>
                    <p>
                      Tel:{" "}
                      {ipoDetail?.registerPhone?.map((phone, index) => (
                        <React.Fragment key={index}>
                          <a href={`tel:${phone.phone}`}>{phone.phone}</a>
                          {index < ipoDetail?.registerPhone.length - 1
                            ? " / "
                            : ""}
                        </React.Fragment>
                      ))}
                    </p>
                    <p>
                      E-mail:{" "}
                      <a href={`mailto:${ipoDetail?.registerEmail}`}>
                        {ipoDetail?.registerEmail}
                      </a>
                    </p>
                    <p>
                      Website:{" "}
                      <a
                        href={ipoDetail?.registerWebsite}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {ipoDetail?.registerWebsite}
                      </a>
                    </p>
                  </div>
                </div>

                <div className="single-ipo-detail-inn-allotment-status-check-section">
                  <div className="section-title">
                    <h2>{ipoDetail?.companyName} IPO Allotment Status Check</h2>
                  </div>
                  <div className="section-sub-detail-allotment-status-check">
                    <p>
                      {ipoDetail?.companyName} IPO allotment status on{" "}
                      {ipoDetail?.registerName} website allotment URL.{" "}
                      <a
                        href={ipoDetail?.registerWebsite}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Click Here
                      </a>
                    </p>
                  </div>
                </div>

                {ipoDetail?.leadManagerName &&
                  ipoDetail.leadManagerName.length > 0 && (
                    <div
                      className="single-ipo-detail-inn-lead-managers-section"
                      id="leadmanagers"
                    >
                      <div className="section-title">
                        <h2>{ipoDetail?.companyName} IPO Lead Managers</h2>
                      </div>
                      <div className="section-sub-detail-lead-manager">
                        <ol>
                          {ipoDetail?.leadManagerName.map((manager, index) => (
                            <li key={index}>{manager?.name}</li>
                          ))}
                        </ol>
                      </div>
                    </div>
                  )}

                <div className="single-ipo-detail-inn-address-section">
                  <div className="section-title">
                    <h2>{ipoDetail?.companyName} Address</h2>
                  </div>
                  <div className="section-sub-detail-address">
                    <p>
                      {ipoDetail?.companyName}
                      <br />
                      {ipoDetail?.address}
                    </p>
                    <p>
                      Phone:{" "}
                      <a href={`tel:${ipoDetail?.companyPhone}`}>
                        {ipoDetail?.companyPhone}
                      </a>
                    </p>
                    <p>
                      E-mail:{" "}
                      <a href={`mailto:${ipoDetail?.email}`}>
                        {ipoDetail?.email}
                      </a>
                    </p>
                    <p>
                      Website:{" "}
                      <a
                        href={ipoDetail?.website}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {ipoDetail?.website}
                      </a>
                    </p>
                  </div>
                </div>

                <div className="single-ipo-detail-inn-about-section" id="about">
                  <div className="section-title">
                    <h2>About {ipoDetail?.companyName}</h2>
                  </div>
                  <div className="section-sub-detail-about">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: ipoDetail?.companyDescription,
                      }}
                    />
                  </div>
                </div>

                <div className="single-ipo-detail-inn-disclaimer-section">
                  <div className="section-title">
                    <h2>Disclaimer</h2>
                  </div>
                  <div className="section-sub-detail-disclaimer">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: ipoDetail?.disclaimer,
                      }}
                    />
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={12} lg={3}>
              <div
                className={`table-of-contents-div ${
                  addClassOnScroll ? "toc_scrolling" : ""
                }`}
              >
                <h5 className="table-of-contents-div-h5">Table of Contents</h5>
                <ul className="table-of-contents-div-ul">
                  {ipoDetail?.IPOstatus === "Live" && (
                    <li>
                      <Link
                        to="livesubscription"
                        smooth={true}
                        duration={100}
                        offset={-100}
                      >
                        Live Subscription
                      </Link>
                    </li>
                  )}
                  {ipoDetail?.subscriptionDetails &&
                    ipoDetail?.subscriptionDetails.length > 0 && (
                      <li>
                        <Link
                          to="subscriptiondetails"
                          smooth={true}
                          duration={100}
                          offset={-100}
                        >
                          Subscription Details
                        </Link>
                      </li>
                    )}
                  <li>
                    <Link
                      to="tentativetimetable"
                      smooth={true}
                      duration={100}
                      offset={-100}
                    >
                      Tentative Timetable
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="ipodetails"
                      smooth={true}
                      duration={100}
                      offset={-100}
                    >
                      IPO Details
                    </Link>
                  </li>
                  {ipoDetail?.Strength?.length > 0 &&
                    ipoDetail?.Risk?.length > 0 &&
                    ipoDetail?.CategoryForIPOS === "MainlineIPO" && (
                      <li>
                        <Link
                          to="keypoint"
                          smooth={true}
                          duration={100}
                          offset={-100}
                        >
                          Strengths & Risks
                        </Link>
                      </li>
                    )}
                  {ipoDetail?.ObjectOfIssue && (
                    <li>
                      <Link
                        to="objectsissue"
                        smooth={true}
                        duration={100}
                        offset={-100}
                      >
                        Objects Of the Issue
                      </Link>
                    </li>
                  )}
                  <li>
                    <Link
                      to="promotersholdings"
                      smooth={true}
                      duration={100}
                      offset={-100}
                    >
                      Promoters Holdings
                    </Link>
                  </li>
                  {ipoDetail?.promotersName &&
                    ipoDetail?.promotersName.length > 0 && (
                      <li>
                        <Link
                          to="promoters"
                          smooth={true}
                          duration={100}
                          offset={-100}
                        >
                          Promoters
                        </Link>
                      </li>
                    )}
                  <li>
                    <Link
                      to="lotsize"
                      smooth={true}
                      duration={100}
                      offset={-100}
                    >
                      Lot Size
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="companyfinancials"
                      smooth={true}
                      duration={100}
                      offset={-100}
                    >
                      Company Financials
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="registrar"
                      smooth={true}
                      duration={100}
                      offset={-100}
                    >
                      Registrar
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="leadmanagers"
                      smooth={true}
                      duration={100}
                      offset={-100}
                    >
                      Lead Managers
                    </Link>
                  </li>
                  <li>
                    <Link to="about" smooth={true} duration={100} offset={-100}>
                      About
                    </Link>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </section>
  );
}

export default IPODetail;
