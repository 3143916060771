import React, { useState, useEffect } from "react";
import { Container, Row, Col, Tab, Nav, Table } from "react-bootstrap";
import axios from "axios";
import { Link } from "react-router-dom";
import Loader from "../Component/Loader";
import { Helmet } from "react-helmet";
import TestimonialSection from "../Component/TestimonialSection";
import FreeAppSection from "../Component/FreeAppSection";
import InterfaceSection from "../Component/InterfaceSection";
import devicefeature from "../asset/images/device-feature.png";
import sectionbg from "../asset/images/section-bg.png";
import uidesign from "../asset/images/ui-design.png";
import information from "../asset/images/information.png";
import subscription from "../asset/images/subscription.png";
import allotment from "../asset/images/allotment.png";
import prediction from "../asset/images/prediction.png";
import listprice from "../asset/images/listprice.png";
import notification from "../asset/images/notification.png";
import messaging from "../asset/images/messaging.png";
import aboutframe from "../asset/images/about-frame.png";
import aboutscreen from "../asset/images/about-screen.png";
import download from "../asset/images/download.png";
import followers from "../asset/images/followers.png";
import reviews from "../asset/images/reviews.png";
import countries from "../asset/images/countries.png";
import nodata from "../asset/images/ListingSoon.webp";
import IPO_Dekho_Feture_img from "../asset/images/IPO_Dekho_Feture_img.jpg";

const HomePage = () => {
  const [liveMainLineIpos, setliveMainLineIpos] = useState([]);
  const [upcomingMainLineIpos, setupcomingMainLineIpos] = useState([]);
  const [listedMainLineIpos, setlistedMainLineIpos] = useState([]);
  const [liveSMEIpos, setLiveSMEIpos] = useState([]);
  const [upcomingSMEIpos, setupcomingSMEIpos] = useState([]);
  const [listedSMEIpos, setlistedSMEIpos] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Start loading
      try {
        const mainLineLiveResponse = await axios.post(
          "https://app.ipodekho.com/GetMainLineIpo",
          {
            CategoryForIPOS: "MainlineIPO",
            type: "Live",
          }
        );
        const mainUpcomingResponse = await axios.post(
          "https://app.ipodekho.com/GetMainLineIpo",
          {
            CategoryForIPOS: "MainlineIPO",
            type: "Upcoming",
          }
        );
        const mainlistedResponse = await axios.post(
          "https://app.ipodekho.com/GetMainLineIpo",
          {
            CategoryForIPOS: "MainlineIPO",
            type: "Listed",
          }
        );
        const smeLiveResponse = await axios.post(
          "https://app.ipodekho.com/GetMainLineIpo",
          {
            CategoryForIPOS: "SmeIPO",
            type: "Live",
          }
        );
        const smeUpcomingResponse = await axios.post(
          "https://app.ipodekho.com/GetMainLineIpo",
          {
            CategoryForIPOS: "SmeIPO",
            type: "Upcoming",
          }
        );
        const smelistedResponse = await axios.post(
          "https://app.ipodekho.com/GetMainLineIpo",
          {
            CategoryForIPOS: "SmeIPO",
            type: "Listed",
          }
        );
        setliveMainLineIpos(mainLineLiveResponse.data.liveIpo.slice(0, 10));
        setupcomingMainLineIpos(
          mainUpcomingResponse.data.upcomingIpo.slice(0, 10)
        );
        setlistedMainLineIpos(mainlistedResponse.data.listedIPO.slice(0, 10));
        setLiveSMEIpos(smeLiveResponse.data.liveIpo.slice(0, 10));
        setupcomingSMEIpos(smeUpcomingResponse.data.upcomingIpo.slice(0, 10));
        setlistedSMEIpos(smelistedResponse.data.listedIPO.slice(0, 10));
        setLoading(false); // Stop loading
      } catch (error) {
        console.error("Error fetching IPO data:", error);
        setLoading(false); // Stop loading even if there is an error
      }
    };
    fetchData();
  }, []);
  function formatDateRange(openDate, closeDate) {
    const options = { day: "numeric", month: "short", year: "numeric" };
    const open = new Date(openDate).toLocaleDateString(undefined, options);
    const close = new Date(closeDate).toLocaleDateString(undefined, options);
    return `${open} to ${close}`;
  }

  function formatDate(adddate) {
    const options = { day: "numeric", month: "short", year: "numeric" };
    const date = new Date(adddate).toLocaleDateString(undefined, options);
    return `${date}`;
  }
  return (
    <>
      <Helmet>

        <title>IPO Dekho - Latest IPO Information, GMP, Allotment Status & Live Subscriptions</title>
        <meta name="description" content="Stay informed with IPO Dekho, your ultimate source for the latest Mainline and SME IPOs. Get GMP details, check allotment status, and view live IPO subscriptions. Effortlessly track upcoming and past IPOs with our comprehensive platform." />
        <meta property="og:url" content="https://ipodekho.com/" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="IPO Dekho - Latest IPO Information, GMP, Allotment Status & Live Subscriptions" />
        <meta property="og:description" content="Stay informed with IPO Dekho, your ultimate source for the latest Mainline and SME IPOs. Get GMP details, check allotment status, and view live IPO subscriptions. Effortlessly track upcoming and past IPOs with our comprehensive platform." />
        <meta property="og:image" content={IPO_Dekho_Feture_img} />

        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="https://ipodekho.com/" />
        <meta property="twitter:url" content="https://ipodekho.com/" />
        <meta name="twitter:title" content="IPO Dekho - Latest IPO Information, GMP, Allotment Status & Live Subscriptions" />
        <meta name="twitter:description" content="Stay informed with IPO Dekho, your ultimate source for the latest Mainline and SME IPOs. Get GMP details, check allotment status, and view live IPO subscriptions. Effortlessly track upcoming and past IPOs with our comprehensive platform." />
        <meta name="twitter:image" content={IPO_Dekho_Feture_img} />
      </Helmet>
      <section className="ipo-detail home_detail">
        <div
          className={`container ${
            liveMainLineIpos.length === 0 ? "upcoming-ipo-tab" : "live-ipo-tab"
          }`}
        >
          <Tab.Container defaultActiveKey="live-ipo-tab">
            <div className="ipo-detail-tabs">
              <nav>
                <Nav variant="tabs" id="nav-tab">
                  <Nav.Item>
                    <Nav.Link eventKey="live-ipo-tab" className="live-ipo-btn">
                      <span>Live IPOs</span>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="upcoming-ipo-tab"
                      className="upcoming-ipo-btn"
                    >
                      <span>Upcoming IPOs</span>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="listed-ipo-tab"
                      className="listed-ipo-btn"
                    >
                      <span>Listed IPOs</span>
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </nav>
              <Tab.Content>
                {/* Live IPO Tab */}
                <Tab.Pane eventKey="live-ipo-tab">
                  <div className="ipo-detail-inner-tabs">
                    <Tab.Container defaultActiveKey="mainboard">
                      <nav className="inner-tabs">
                        <Nav variant="tabs">
                          <Nav.Item>
                            <Nav.Link eventKey="mainboard">
                              <span>Mainboard</span>
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="sme">
                              <span>SME</span>
                            </Nav.Link>
                          </Nav.Item>
                        </Nav>
                      </nav>
                      <Tab.Content>
                        <Tab.Pane eventKey="mainboard">
                          <div className="d-none d-md-block">
                            {loading ? (
                              <div className="text-center">
                                <Loader />
                              </div>
                            ) : (
                              <Table bordered className="ipo_table">
                                {liveMainLineIpos.length === 0 ? (
                                  <div>
                                    <center>
                                      <img
                                        src={nodata}
                                        class="img-fluid"
                                        alt="sectionbg"
                                      />
                                    </center>
                                  </div>
                                ) : (
                                  <>
                                    <thead>
                                      <tr>
                                        <th colSpan="2">IPO Name</th>
                                        <th>Offer Date</th>
                                        <th>Offer Price</th>
                                        <th>Lot Size</th>
                                        <th>Subs</th>
                                        <th colSpan="2">GMP</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {liveMainLineIpos.map((ipo, index) => (
                                        <tr key={ipo.id}>
                                          <td className="ipo-image">
                                            <img
                                              src={ipo.file}
                                              alt={ipo.companyName}
                                            />
                                          </td>
                                          <td className="ipo-name">
                                            <span
                                              className={`status ${ipo.IPOStatus.toLowerCase()}`}
                                            >
                                              {ipo?.IPOStatus ===
                                              "WaitingAllotment"
                                                ? "Waiting Allotment"
                                                : null}
                                              {ipo?.IPOStatus === "AllotmentOut"
                                                ? " Allotment Out"
                                                : null}
                                              {ipo?.IPOStatus ===
                                              "AllotmentToday"
                                                ? "Allotment Today"
                                                : null}
                                              {ipo?.IPOStatus === "Live"
                                                ? "Live"
                                                : null}
                                              {ipo?.IPOStatus === "Upcoming"
                                                ? "Upcoming"
                                                : null}
                                              {ipo?.IPOStatus === "Listed"
                                                ? "Listed"
                                                : null}
                                            </span>
                                            <Link
                                              className="ipo_name btn btn-primary"
                                              to={`/ipo/${ipo.slug}`}
                                            >
                                              <strong>{ipo.companyName}</strong>
                                            </Link>
                                          </td>
                                          <td className="ipo-date">
                                            {formatDateRange(
                                              ipo.IPOOpenDate,
                                              ipo.IPOCloseDate
                                            )}
                                          </td>
                                          <td className="ipo-price">
                                            ₹{ipo.fromPrice} to ₹{ipo.toPrice}
                                          </td>
                                          <td className="ipo-lot">
                                            {ipo.lotSize} Shares
                                          </td>
                                          <td className="ipo-subs">
                                            {ipo.total} x
                                          </td>
                                          <td
                                            className={`ipo-gmp ${
                                              ipo.GMP < 0
                                                ? "false"
                                                : ipo.GMP > 0
                                                ? "true"
                                                : "neutral"
                                            }`}
                                          >
                                            {isNaN(ipo.GMP)
                                              ? "-"
                                              : `₹${ipo.GMP}`}{" "}
                                            {isNaN(ipo.GMP) ||
                                            isNaN(ipo.toPrice)
                                              ? ""
                                              : `(${(
                                                  (ipo.GMP * 100) /
                                                  ipo.toPrice
                                                ).toFixed(2)}%)`}
                                          </td>
                                          <td className="ipo-link">
                                            <Link
                                              className="btn btn-primary"
                                              to={`/ipo/${ipo.slug}`}
                                            >
                                              View
                                            </Link>
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </>
                                )}
                              </Table>
                            )}
                          </div>
                          <div className="d-block d-md-none">
                            {loading ? (
                              <div className="text-center">
                                <Loader />
                              </div>
                            ) : (
                              <>
                                {liveMainLineIpos.length === 0 ? (
                                  <div>
                                    <center>
                                      <img
                                        src={nodata}
                                        class="img-fluid"
                                        alt="sectionbg"
                                      />
                                    </center>
                                  </div>
                                ) : (
                                  <>
                                    <div className="mobile_ipo text-start">
                                      {liveMainLineIpos.map((ipo, index) => (
                                        <Link
                                          className="mobile_card"
                                          to={`/ipo/${ipo.slug}`}
                                          key={ipo.id}
                                        >
                                          <span
                                            className={`status ${ipo.IPOStatus.toLowerCase()}`}
                                          >
                                            {ipo?.IPOStatus ===
                                            "WaitingAllotment"
                                              ? "Waiting Allotment"
                                              : null}
                                            {ipo?.IPOStatus === "AllotmentOut"
                                              ? " Allotment Out"
                                              : null}
                                            {ipo?.IPOStatus === "AllotmentToday"
                                              ? "Allotment Today"
                                              : null}
                                            {ipo?.IPOStatus === "Live"
                                              ? "Live"
                                              : null}
                                            {ipo?.IPOStatus === "Upcoming"
                                              ? "Upcoming"
                                              : null}
                                            {ipo?.IPOStatus === "Listed"
                                              ? "Listed"
                                              : null}
                                          </span>
                                          <Row className="align-items-center">
                                            <Col xs={4}>
                                              <img
                                                src={ipo.file}
                                                alt={ipo.companyName}
                                                className="img-fluid ipo_company_logo"
                                              />
                                            </Col>
                                            <Col xs={8}>
                                              <h6 className="mb-0 ipo_companyName">
                                                {ipo.companyName}
                                              </h6>
                                            </Col>
                                          </Row>
                                          <Row className="top_border">
                                            <Col
                                              xs={8}
                                              className="border_right"
                                            >
                                              <p className="small card_title mb-0">
                                                Offer Date
                                              </p>
                                              <p className="mb-0 card_info fw-medium">
                                                {formatDateRange(
                                                  ipo.IPOOpenDate,
                                                  ipo.IPOCloseDate
                                                )}
                                              </p>
                                            </Col>
                                            <Col xs={4}>
                                              <p className="small card_title mb-0">
                                                Offer Price
                                              </p>
                                              <p className="mb-0 card_info fw-medium">
                                                ₹{ipo.fromPrice} to ₹
                                                {ipo.toPrice}
                                              </p>
                                            </Col>
                                          </Row>
                                          <Row className="top_border">
                                            <Col
                                              xs={4}
                                              className="border_right"
                                            >
                                              <p className="small card_title mb-0">
                                                Lot Size
                                              </p>
                                              <p className="mb-0 card_info fw-medium">
                                                {ipo.lotSize} Shares
                                              </p>
                                            </Col>
                                            <Col
                                              xs={3}
                                              className="border_right"
                                            >
                                              <p className="small card_title mb-0">
                                                Subs
                                              </p>
                                              <p className="mb-0 card_info fw-medium">
                                                {ipo.total} x
                                              </p>
                                            </Col>
                                            <Col xs={5}>
                                              <p className="small card_title mb-0">
                                                Premium (GMP)
                                              </p>
                                              <p
                                                className={`mb-0 ipo-gmp ${
                                                  ipo.GMP < 0
                                                    ? "false"
                                                    : ipo.GMP > 0
                                                    ? "true"
                                                    : "neutral"
                                                }`}
                                              >
                                                {isNaN(ipo.GMP)
                                                  ? "-"
                                                  : `₹${ipo.GMP}`}{" "}
                                                {isNaN(ipo.GMP) ||
                                                isNaN(ipo.toPrice)
                                                  ? ""
                                                  : `(${(
                                                      (ipo.GMP * 100) /
                                                      ipo.toPrice
                                                    ).toFixed(2)}%)`}
                                              </p>
                                            </Col>
                                          </Row>
                                          <Row className="top_border">
                                            <Col>
                                              <p className="ipo_card_link mb-0">
                                                View More Information
                                              </p>
                                            </Col>
                                          </Row>
                                        </Link>
                                      ))}
                                    </div>
                                  </>
                                )}
                              </>
                            )}
                          </div>
                          <div className="text-center mt-3">
                            <Link
                              to="/mainboard-live-ipos"
                              className="btn btn-ipo viewall_btn"
                            >
                              View All Mainboard Live IPOs
                            </Link>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="sme">
                          <div className="d-none d-md-block">
                            {loading ? (
                              <div className="text-center">
                                <Loader />
                              </div>
                            ) : (
                              <Table bordered className="ipo_table">
                                {liveSMEIpos.length === 0 ? (
                                  <div>
                                    <center>
                                      <img
                                        src={nodata}
                                        class="img-fluid"
                                        alt="sectionbg"
                                      />
                                    </center>
                                  </div>
                                ) : (
                                  <>
                                    <thead>
                                      <tr>
                                        <th colSpan="2">IPO Name</th>
                                        <th>Offer Date</th>
                                        <th>Offer Price</th>
                                        <th>Lot Size</th>
                                        <th>Subs</th>
                                        <th colSpan="2">GMP</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {liveSMEIpos.map((ipo, index) => (
                                        <tr key={ipo.id}>
                                          <td className="ipo-image">
                                            <img
                                              src={ipo.file}
                                              alt={ipo.companyName}
                                            />
                                          </td>
                                          <td className="ipo-name">
                                            <span
                                              className={`status ${ipo.IPOStatus.toLowerCase()}`}
                                            >
                                              {ipo?.IPOStatus ===
                                              "WaitingAllotment"
                                                ? "Waiting Allotment"
                                                : null}
                                              {ipo?.IPOStatus === "AllotmentOut"
                                                ? " Allotment Out"
                                                : null}
                                              {ipo?.IPOStatus ===
                                              "AllotmentToday"
                                                ? "Allotment Today"
                                                : null}
                                              {ipo?.IPOStatus === "Live"
                                                ? "Live"
                                                : null}
                                              {ipo?.IPOStatus === "Upcoming"
                                                ? "Upcoming"
                                                : null}
                                              {ipo?.IPOStatus === "Listed"
                                                ? "Listed"
                                                : null}
                                            </span>
                                            <Link
                                              className="ipo_name btn btn-primary"
                                              to={`/ipo/${ipo.slug}`}
                                            >
                                              <strong>{ipo.companyName}</strong>
                                            </Link>
                                          </td>
                                          <td className="ipo-date">
                                            {formatDateRange(
                                              ipo.IPOOpenDate,
                                              ipo.IPOCloseDate
                                            )}
                                          </td>
                                          <td className="ipo-price">
                                            ₹{ipo.fromPrice} to ₹{ipo.toPrice}
                                          </td>
                                          <td className="ipo-lot">
                                            {ipo.lotSize} Shares
                                          </td>
                                          <td className="ipo-subs">
                                            {ipo.total} x
                                          </td>
                                          <td
                                            className={`ipo-gmp ${
                                              ipo.GMP < 0
                                                ? "false"
                                                : ipo.GMP > 0
                                                ? "true"
                                                : "neutral"
                                            }`}
                                          >
                                            {isNaN(ipo.GMP)
                                              ? "-"
                                              : `₹${ipo.GMP}`}{" "}
                                            {isNaN(ipo.GMP) ||
                                            isNaN(ipo.toPrice)
                                              ? ""
                                              : `(${(
                                                  (ipo.GMP * 100) /
                                                  ipo.toPrice
                                                ).toFixed(2)}%)`}
                                          </td>
                                          <td className="ipo-link">
                                            <Link
                                              className="btn btn-primary"
                                              to={`/ipo/${ipo.slug}`}
                                            >
                                              View
                                            </Link>
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </>
                                )}
                              </Table>
                            )}
                          </div>
                          <div className="d-block d-md-none">
                            {loading ? (
                              <div className="text-center">
                                <Loader />
                              </div>
                            ) : (
                              <>
                                {liveSMEIpos.length === 0 ? (
                                  <div>
                                    <center>
                                      <img
                                        src={nodata}
                                        class="img-fluid"
                                        alt="sectionbg"
                                      />
                                    </center>
                                  </div>
                                ) : (
                                  <>
                                    <div className="mobile_ipo text-start">
                                      {liveSMEIpos.map((ipo, index) => (
                                        <Link
                                          className="mobile_card"
                                          to={`/ipo/${ipo.slug}`}
                                          key={ipo.id}
                                        >
                                          <span
                                            className={`status ${ipo.IPOStatus.toLowerCase()}`}
                                          >
                                            {ipo?.IPOStatus ===
                                            "WaitingAllotment"
                                              ? "Waiting Allotment"
                                              : null}
                                            {ipo?.IPOStatus === "AllotmentOut"
                                              ? " Allotment Out"
                                              : null}
                                            {ipo?.IPOStatus === "AllotmentToday"
                                              ? "Allotment Today"
                                              : null}
                                            {ipo?.IPOStatus === "Live"
                                              ? "Live"
                                              : null}
                                            {ipo?.IPOStatus === "Upcoming"
                                              ? "Upcoming"
                                              : null}
                                            {ipo?.IPOStatus === "Listed"
                                              ? "Listed"
                                              : null}
                                          </span>
                                          <Row className="align-items-center">
                                            <Col xs={4}>
                                              <img
                                                src={ipo.file}
                                                alt={ipo.companyName}
                                                className="img-fluid ipo_company_logo"
                                              />
                                            </Col>
                                            <Col xs={8}>
                                              <h6 className="mb-0 ipo_companyName">
                                                {ipo.companyName}
                                              </h6>
                                            </Col>
                                          </Row>
                                          <Row className="top_border">
                                            <Col
                                              xs={8}
                                              className="border_right"
                                            >
                                              <p className="small card_title mb-0">
                                                Offer Date
                                              </p>
                                              <p className="mb-0 card_info fw-medium">
                                                {formatDateRange(
                                                  ipo.IPOOpenDate,
                                                  ipo.IPOCloseDate
                                                )}
                                              </p>
                                            </Col>
                                            <Col xs={4}>
                                              <p className="small card_title mb-0">
                                                Offer Price
                                              </p>
                                              <p className="mb-0 card_info fw-medium">
                                                ₹{ipo.fromPrice} to ₹
                                                {ipo.toPrice}
                                              </p>
                                            </Col>
                                          </Row>
                                          <Row className="top_border">
                                            <Col
                                              xs={4}
                                              className="border_right"
                                            >
                                              <p className="small card_title mb-0">
                                                Lot Size
                                              </p>
                                              <p className="mb-0 card_info fw-medium">
                                                {ipo.lotSize} Shares
                                              </p>
                                            </Col>
                                            <Col
                                              xs={3}
                                              className="border_right"
                                            >
                                              <p className="small card_title mb-0">
                                                Subs
                                              </p>
                                              <p className="mb-0 card_info fw-medium">
                                                {ipo.total} x
                                              </p>
                                            </Col>
                                            <Col xs={5}>
                                              <p className="small card_title mb-0">
                                                Premium (GMP)
                                              </p>
                                              <p
                                                className={`mb-0 ipo-gmp ${
                                                  ipo.GMP < 0
                                                    ? "false"
                                                    : ipo.GMP > 0
                                                    ? "true"
                                                    : "neutral"
                                                }`}
                                              >
                                                {isNaN(ipo.GMP)
                                                  ? "-"
                                                  : `₹${ipo.GMP}`}{" "}
                                                {isNaN(ipo.GMP) ||
                                                isNaN(ipo.toPrice)
                                                  ? ""
                                                  : `(${(
                                                      (ipo.GMP * 100) /
                                                      ipo.toPrice
                                                    ).toFixed(2)}%)`}
                                              </p>
                                            </Col>
                                          </Row>
                                          <Row className="top_border">
                                            <Col>
                                              <p className="ipo_card_link mb-0">
                                                View More Information
                                              </p>
                                            </Col>
                                          </Row>
                                        </Link>
                                      ))}
                                    </div>
                                  </>
                                )}
                              </>
                            )}
                          </div>
                          <div className="text-center mt-3">
                            <Link
                              to="/sme-live-ipos"
                              className="btn btn-ipo viewall_btn"
                            >
                              View All SME Live IPOs
                            </Link>
                          </div>
                        </Tab.Pane>
                      </Tab.Content>
                    </Tab.Container>
                  </div>
                </Tab.Pane>
                {/* Upcoming IPO Tab */}
                <Tab.Pane eventKey="upcoming-ipo-tab">
                  <div className="ipo-detail-inner-tabs">
                    <Tab.Container defaultActiveKey="upcoming-mainboard">
                      <nav className="inner-tabs">
                        <Nav variant="tabs">
                          <Nav.Item>
                            <Nav.Link eventKey="upcoming-mainboard">
                              <span>Mainboard</span>
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="upcoming-sme">
                              <span>SME</span>
                            </Nav.Link>
                          </Nav.Item>
                        </Nav>
                      </nav>
                      <Tab.Content>
                        <Tab.Pane eventKey="upcoming-mainboard">
                          <div className="d-none d-md-block">
                            {loading ? (
                              <div className="text-center">
                                <Loader />
                              </div>
                            ) : (
                              <Table bordered className="ipo_table">
                                {upcomingMainLineIpos.length === 0 ? (
                                  <div>
                                    <center>
                                      <img
                                        src={nodata}
                                        class="img-fluid"
                                        alt="sectionbg"
                                      />
                                    </center>
                                  </div>
                                ) : (
                                  <>
                                    <thead>
                                      <tr>
                                        <th colSpan="2">IPO Name</th>
                                        <th>Offer Date</th>
                                        <th>Offer Price</th>
                                        <th>Lot Size</th>
                                        <th>Subs</th>
                                        <th colSpan="2">GMP</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {upcomingMainLineIpos.map(
                                        (ipo, index) => (
                                          <tr key={ipo.id}>
                                            <td className="ipo-image">
                                              <img
                                                src={ipo.file}
                                                alt={ipo.companyName}
                                              />
                                            </td>
                                            <td className="ipo-name">
                                              <span
                                                className={`status ${ipo.IPOStatus.toLowerCase()}`}
                                              >
                                                {ipo?.IPOStatus ===
                                                "WaitingAllotment"
                                                  ? "Waiting Allotment"
                                                  : null}
                                                {ipo?.IPOStatus ===
                                                "AllotmentOut"
                                                  ? " Allotment Out"
                                                  : null}
                                                {ipo?.IPOStatus ===
                                                "AllotmentToday"
                                                  ? "Allotment Today"
                                                  : null}
                                                {ipo?.IPOStatus === "Live"
                                                  ? "Live"
                                                  : null}
                                                {ipo?.IPOStatus === "Upcoming"
                                                  ? "Upcoming"
                                                  : null}
                                                {ipo?.IPOStatus === "Listed"
                                                  ? "Listed"
                                                  : null}
                                              </span>
                                              <Link
                                                className="ipo_name btn btn-primary"
                                                to={`/ipo/${ipo.slug}`}
                                              >
                                                <strong>
                                                  {ipo.companyName}
                                                </strong>
                                              </Link>
                                            </td>
                                            <td className="ipo-date">
                                              {formatDateRange(
                                                ipo.IPOOpenDate,
                                                ipo.IPOCloseDate
                                              )}
                                            </td>
                                            <td className="ipo-price">
                                              ₹{ipo.fromPrice} to ₹{ipo.toPrice}
                                            </td>
                                            <td className="ipo-lot">
                                              {ipo.lotSize} Shares
                                            </td>
                                            <td className="ipo-subs">
                                              {ipo.total} x
                                            </td>
                                            <td
                                              className={`ipo-gmp ${
                                                ipo.GMP < 0
                                                  ? "false"
                                                  : ipo.GMP > 0
                                                  ? "true"
                                                  : "neutral"
                                              }`}
                                            >
                                              {isNaN(ipo.GMP)
                                                ? "-"
                                                : `₹${ipo.GMP}`}{" "}
                                              {isNaN(ipo.GMP) ||
                                              isNaN(ipo.toPrice)
                                                ? ""
                                                : `(${(
                                                    (ipo.GMP * 100) /
                                                    ipo.toPrice
                                                  ).toFixed(2)}%)`}
                                            </td>
                                            <td className="ipo-link">
                                              <Link
                                                className="btn btn-primary"
                                                to={`/ipo/${ipo.slug}`}
                                              >
                                                View
                                              </Link>
                                            </td>
                                          </tr>
                                        )
                                      )}
                                    </tbody>
                                  </>
                                )}
                              </Table>
                            )}
                          </div>
                          <div className="d-block d-md-none">
                            {loading ? (
                              <div className="text-center">
                                <Loader />
                              </div>
                            ) : (
                              <>
                                {upcomingMainLineIpos.length === 0 ? (
                                  <div>
                                    <center>
                                      <img
                                        src={nodata}
                                        class="img-fluid"
                                        alt="sectionbg"
                                      />
                                    </center>
                                  </div>
                                ) : (
                                  <>
                                    <div className="mobile_ipo text-start">
                                      {upcomingMainLineIpos.map(
                                        (ipo, index) => (
                                          <Link
                                            className="mobile_card"
                                            to={`/ipo/${ipo.slug}`}
                                            key={ipo.id}
                                          >
                                            <span
                                              className={`status ${ipo.IPOStatus.toLowerCase()}`}
                                            >
                                              {ipo?.IPOStatus ===
                                              "WaitingAllotment"
                                                ? "Waiting Allotment"
                                                : null}
                                              {ipo?.IPOStatus === "AllotmentOut"
                                                ? " Allotment Out"
                                                : null}
                                              {ipo?.IPOStatus ===
                                              "AllotmentToday"
                                                ? "Allotment Today"
                                                : null}
                                              {ipo?.IPOStatus === "Live"
                                                ? "Live"
                                                : null}
                                              {ipo?.IPOStatus === "Upcoming"
                                                ? "Upcoming"
                                                : null}
                                              {ipo?.IPOStatus === "Listed"
                                                ? "Listed"
                                                : null}
                                            </span>
                                            <Row className="align-items-center">
                                              <Col xs={4}>
                                                <img
                                                  src={ipo.file}
                                                  alt={ipo.companyName}
                                                  className="img-fluid ipo_company_logo"
                                                />
                                              </Col>
                                              <Col xs={8}>
                                                <h6 className="mb-0 ipo_companyName">
                                                  {ipo.companyName}
                                                </h6>
                                              </Col>
                                            </Row>
                                            <Row className="top_border">
                                              <Col
                                                xs={8}
                                                className="border_right"
                                              >
                                                <p className="small card_title mb-0">
                                                  Offer Date
                                                </p>
                                                <p className="mb-0 card_info fw-medium">
                                                  {formatDateRange(
                                                    ipo.IPOOpenDate,
                                                    ipo.IPOCloseDate
                                                  )}
                                                </p>
                                              </Col>
                                              <Col xs={4}>
                                                <p className="small card_title mb-0">
                                                  Offer Price
                                                </p>
                                                <p className="mb-0 card_info fw-medium">
                                                  ₹{ipo.fromPrice} to ₹
                                                  {ipo.toPrice}
                                                </p>
                                              </Col>
                                            </Row>
                                            <Row className="top_border">
                                              <Col
                                                xs={4}
                                                className="border_right"
                                              >
                                                <p className="small card_title mb-0">
                                                  Lot Size
                                                </p>
                                                <p className="mb-0 card_info fw-medium">
                                                  {ipo.lotSize} Shares
                                                </p>
                                              </Col>
                                              <Col
                                                xs={3}
                                                className="border_right"
                                              >
                                                <p className="small card_title mb-0">
                                                  Subs
                                                </p>
                                                <p className="mb-0 card_info fw-medium">
                                                  {ipo.total} x
                                                </p>
                                              </Col>
                                              <Col xs={5}>
                                                <p className="small card_title mb-0">
                                                  Premium (GMP)
                                                </p>
                                                <p
                                                  className={`mb-0 ipo-gmp ${
                                                    ipo.GMP < 0
                                                      ? "false"
                                                      : ipo.GMP > 0
                                                      ? "true"
                                                      : "neutral"
                                                  }`}
                                                >
                                                  {isNaN(ipo.GMP)
                                                    ? "-"
                                                    : `₹${ipo.GMP}`}{" "}
                                                  {isNaN(ipo.GMP) ||
                                                  isNaN(ipo.toPrice)
                                                    ? ""
                                                    : `(${(
                                                        (ipo.GMP * 100) /
                                                        ipo.toPrice
                                                      ).toFixed(2)}%)`}
                                                </p>
                                              </Col>
                                            </Row>
                                            <Row className="top_border">
                                              <Col>
                                                <p className="ipo_card_link mb-0">
                                                  View More Information
                                                </p>
                                              </Col>
                                            </Row>
                                          </Link>
                                        )
                                      )}
                                    </div>
                                  </>
                                )}
                              </>
                            )}
                          </div>
                          <div className="text-center mt-3">
                            <Link
                              to="/mainboard-upcoming-ipos"
                              className="btn btn-ipo viewall_btn"
                            >
                              View All Mainboard Upcoming IPOs
                            </Link>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="upcoming-sme">
                          <div className="d-none d-md-block">
                            {loading ? (
                              <div className="text-center">
                                <Loader />
                              </div>
                            ) : (
                              <Table bordered className="ipo_table">
                                {upcomingSMEIpos.length === 0 ? (
                                  <div>
                                    <center>
                                      <img
                                        src={nodata}
                                        class="img-fluid"
                                        alt="sectionbg"
                                      />
                                    </center>
                                  </div>
                                ) : (
                                  <>
                                    <thead>
                                      <tr>
                                        <th colSpan="2">IPO Name</th>
                                        <th>Offer Date</th>
                                        <th>Offer Price</th>
                                        <th>Lot Size</th>
                                        <th>Subs</th>
                                        <th colSpan="2">GMP</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {upcomingSMEIpos.map((ipo, index) => (
                                        <tr key={ipo.id}>
                                          <td className="ipo-image">
                                            <img
                                              src={ipo.file}
                                              alt={ipo.companyName}
                                            />
                                          </td>
                                          <td className="ipo-name">
                                            <span
                                              className={`status ${ipo.IPOStatus.toLowerCase()}`}
                                            >
                                              {ipo?.IPOStatus ===
                                              "WaitingAllotment"
                                                ? "Waiting Allotment"
                                                : null}
                                              {ipo?.IPOStatus === "AllotmentOut"
                                                ? " Allotment Out"
                                                : null}
                                              {ipo?.IPOStatus ===
                                              "AllotmentToday"
                                                ? "Allotment Today"
                                                : null}
                                              {ipo?.IPOStatus === "Live"
                                                ? "Live"
                                                : null}
                                              {ipo?.IPOStatus === "Upcoming"
                                                ? "Upcoming"
                                                : null}
                                              {ipo?.IPOStatus === "Listed"
                                                ? "Listed"
                                                : null}
                                            </span>
                                            <Link
                                              className="ipo_name btn btn-primary"
                                              to={`/ipo/${ipo.slug}`}
                                            >
                                              <strong>{ipo.companyName}</strong>
                                            </Link>
                                          </td>
                                          <td className="ipo-date">
                                            {formatDateRange(
                                              ipo.IPOOpenDate,
                                              ipo.IPOCloseDate
                                            )}
                                          </td>
                                          <td className="ipo-price">
                                            ₹{ipo.fromPrice} to ₹{ipo.toPrice}
                                          </td>
                                          <td className="ipo-lot">
                                            {ipo.lotSize} Shares
                                          </td>
                                          <td className="ipo-subs">
                                            {ipo.total} x
                                          </td>
                                          <td
                                            className={`ipo-gmp ${
                                              ipo.GMP < 0
                                                ? "false"
                                                : ipo.GMP > 0
                                                ? "true"
                                                : "neutral"
                                            }`}
                                          >
                                            {isNaN(ipo.GMP)
                                              ? "-"
                                              : `₹${ipo.GMP}`}{" "}
                                            {isNaN(ipo.GMP) ||
                                            isNaN(ipo.toPrice)
                                              ? ""
                                              : `(${(
                                                  (ipo.GMP * 100) /
                                                  ipo.toPrice
                                                ).toFixed(2)}%)`}
                                          </td>
                                          <td className="ipo-link">
                                            <Link
                                              className="btn btn-primary"
                                              to={`/ipo/${ipo.slug}`}
                                            >
                                              View
                                            </Link>
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </>
                                )}
                              </Table>
                            )}
                          </div>
                          <div className="d-block d-md-none">
                            {loading ? (
                              <div className="text-center">
                                <Loader />
                              </div>
                            ) : (
                              <>
                                {upcomingSMEIpos.length === 0 ? (
                                  <div>
                                    <center>
                                      <img
                                        src={nodata}
                                        class="img-fluid"
                                        alt="sectionbg"
                                      />
                                    </center>
                                  </div>
                                ) : (
                                  <>
                                    <div className="mobile_ipo text-start">
                                      {upcomingSMEIpos.map((ipo, index) => (
                                        <Link
                                          className="mobile_card"
                                          to={`/ipo/${ipo.slug}`}
                                          key={ipo.id}
                                        >
                                          <span
                                            className={`status ${ipo.IPOStatus.toLowerCase()}`}
                                          >
                                            {ipo?.IPOStatus ===
                                            "WaitingAllotment"
                                              ? "Waiting Allotment"
                                              : null}
                                            {ipo?.IPOStatus === "AllotmentOut"
                                              ? " Allotment Out"
                                              : null}
                                            {ipo?.IPOStatus === "AllotmentToday"
                                              ? "Allotment Today"
                                              : null}
                                            {ipo?.IPOStatus === "Live"
                                              ? "Live"
                                              : null}
                                            {ipo?.IPOStatus === "Upcoming"
                                              ? "Upcoming"
                                              : null}
                                            {ipo?.IPOStatus === "Listed"
                                              ? "Listed"
                                              : null}
                                          </span>
                                          <Row className="align-items-center">
                                            <Col xs={4}>
                                              <img
                                                src={ipo.file}
                                                alt={ipo.companyName}
                                                className="img-fluid ipo_company_logo"
                                              />
                                            </Col>
                                            <Col xs={8}>
                                              <h6 className="mb-0 ipo_companyName">
                                                {ipo.companyName}
                                              </h6>
                                            </Col>
                                          </Row>
                                          <Row className="top_border">
                                            <Col
                                              xs={8}
                                              className="border_right"
                                            >
                                              <p className="small card_title mb-0">
                                                Offer Date
                                              </p>
                                              <p className="mb-0 card_info fw-medium">
                                                {formatDateRange(
                                                  ipo.IPOOpenDate,
                                                  ipo.IPOCloseDate
                                                )}
                                              </p>
                                            </Col>
                                            <Col xs={4}>
                                              <p className="small card_title mb-0">
                                                Offer Price
                                              </p>
                                              <p className="mb-0 card_info fw-medium">
                                                ₹{ipo.fromPrice} to ₹
                                                {ipo.toPrice}
                                              </p>
                                            </Col>
                                          </Row>
                                          <Row className="top_border">
                                            <Col
                                              xs={4}
                                              className="border_right"
                                            >
                                              <p className="small card_title mb-0">
                                                Lot Size
                                              </p>
                                              <p className="mb-0 card_info fw-medium">
                                                {ipo.lotSize} Shares
                                              </p>
                                            </Col>
                                            <Col
                                              xs={3}
                                              className="border_right"
                                            >
                                              <p className="small card_title mb-0">
                                                Subs
                                              </p>
                                              <p className="mb-0 card_info fw-medium">
                                                {ipo.total} x
                                              </p>
                                            </Col>
                                            <Col xs={5}>
                                              <p className="small card_title mb-0">
                                                Premium (GMP)
                                              </p>
                                              <p
                                                className={`mb-0 ipo-gmp ${
                                                  ipo.GMP < 0
                                                    ? "false"
                                                    : ipo.GMP > 0
                                                    ? "true"
                                                    : "neutral"
                                                }`}
                                              >
                                                {isNaN(ipo.GMP)
                                                  ? "-"
                                                  : `₹${ipo.GMP}`}{" "}
                                                {isNaN(ipo.GMP) ||
                                                isNaN(ipo.toPrice)
                                                  ? ""
                                                  : `(${(
                                                      (ipo.GMP * 100) /
                                                      ipo.toPrice
                                                    ).toFixed(2)}%)`}
                                              </p>
                                            </Col>
                                          </Row>
                                          <Row className="top_border">
                                            <Col>
                                              <p className="ipo_card_link mb-0">
                                                View More Information
                                              </p>
                                            </Col>
                                          </Row>
                                        </Link>
                                      ))}
                                    </div>
                                  </>
                                )}
                              </>
                            )}
                          </div>
                          <div className="text-center mt-3">
                            <Link
                              to="/sme-upcoming-ipos"
                              className="btn btn-ipo viewall_btn"
                            >
                              View All SME Upcoming IPOs
                            </Link>
                          </div>
                        </Tab.Pane>
                      </Tab.Content>
                    </Tab.Container>
                  </div>
                </Tab.Pane>
                {/* Listed IPO Tab */}
                <Tab.Pane eventKey="listed-ipo-tab">
                  <div className="ipo-detail-inner-tabs">
                    <Tab.Container defaultActiveKey="listed-mainboard">
                      <nav className="inner-tabs">
                        <Nav variant="tabs">
                          <Nav.Item>
                            <Nav.Link eventKey="listed-mainboard">
                              <span>Mainboard</span>
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="listed-sme">
                              <span>SME</span>
                            </Nav.Link>
                          </Nav.Item>
                        </Nav>
                      </nav>
                      <Tab.Content>
                        <Tab.Pane eventKey="listed-mainboard">
                          <div className="d-none d-md-block">
                            {loading ? (
                              <div className="text-center">
                                <Loader />
                              </div>
                            ) : (
                              <Table bordered className="ipo_table">
                                {listedMainLineIpos.length === 0 ? (
                                  <div>
                                    <center>
                                      <img
                                        src={nodata}
                                        class="img-fluid"
                                        alt="sectionbg"
                                      />
                                    </center>
                                  </div>
                                ) : (
                                  <>
                                    <thead>
                                      <tr>
                                        <th colSpan="2">IPO Name</th>
                                        <th>Offer Date</th>
                                        <th>Offer Price</th>
                                        <th>Lot Size</th>
                                        <th>Subs</th>
                                        <th colSpan="2">GMP</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {listedMainLineIpos.map((ipo, index) => (
                                        <tr key={ipo.id}>
                                          <td className="ipo-image">
                                            <img
                                              src={ipo.file}
                                              alt={ipo.companyName}
                                            />
                                          </td>
                                          <td className="ipo-name">
                                            <span
                                              className={`status ${ipo.IPOStatus.toLowerCase()}`}
                                            >
                                              {ipo?.IPOStatus ===
                                              "WaitingAllotment"
                                                ? "Waiting Allotment"
                                                : null}
                                              {ipo?.IPOStatus === "AllotmentOut"
                                                ? " Allotment Out"
                                                : null}
                                              {ipo?.IPOStatus ===
                                              "AllotmentToday"
                                                ? "Allotment Today"
                                                : null}
                                              {ipo?.IPOStatus === "Live"
                                                ? "Live"
                                                : null}
                                              {ipo?.IPOStatus === "Upcoming"
                                                ? "Upcoming"
                                                : null}
                                              {ipo?.IPOStatus === "Listed"
                                                ? "Listed"
                                                : null}
                                            </span>
                                            <Link
                                              className="ipo_name btn btn-primary"
                                              to={`/ipo/${ipo.slug}`}
                                            >
                                              <strong>{ipo.companyName}</strong>
                                            </Link>
                                          </td>
                                          <td className="ipo-date">
                                            {formatDateRange(
                                              ipo.IPOOpenDate,
                                              ipo.IPOCloseDate
                                            )}
                                          </td>
                                          <td className="ipo-price">
                                            ₹{ipo.fromPrice} to ₹{ipo.toPrice}
                                          </td>
                                          <td className="ipo-lot">
                                            {ipo.lotSize} Shares
                                          </td>
                                          <td className="ipo-subs">
                                            {ipo.total} x
                                          </td>
                                          <td
                                            className={`ipo-gmp ${
                                              ipo.GMP < 0
                                                ? "false"
                                                : ipo.GMP > 0
                                                ? "true"
                                                : "neutral"
                                            }`}
                                          >
                                            {isNaN(ipo.GMP)
                                              ? "-"
                                              : `₹${ipo.GMP}`}{" "}
                                            {isNaN(ipo.GMP) ||
                                            isNaN(ipo.toPrice)
                                              ? ""
                                              : `(${(
                                                  (ipo.GMP * 100) /
                                                  ipo.toPrice
                                                ).toFixed(2)}%)`}
                                          </td>
                                          <td className="ipo-link">
                                            <Link
                                              className="btn btn-primary"
                                              to={`/ipo/${ipo.slug}`}
                                            >
                                              View
                                            </Link>
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </>
                                )}
                              </Table>
                            )}
                          </div>
                          <div className="d-block d-md-none">
                            {loading ? (
                              <div className="text-center">
                                <Loader />
                              </div>
                            ) : (
                              <>
                                {listedMainLineIpos.length === 0 ? (
                                  <div>
                                    <center>
                                      <img
                                        src={nodata}
                                        class="img-fluid"
                                        alt="sectionbg"
                                      />
                                    </center>
                                  </div>
                                ) : (
                                  <>
                                    <div className="mobile_ipo text-start">
                                      {listedMainLineIpos.map((ipo, index) => (
                                        <Link
                                          className="mobile_card"
                                          to={`/ipo/${ipo.slug}`}
                                          key={ipo.id}
                                        >
                                          <span
                                            className={`status ${ipo.IPOStatus.toLowerCase()}`}
                                          >
                                            {ipo?.IPOStatus ===
                                            "WaitingAllotment"
                                              ? "Waiting Allotment"
                                              : null}
                                            {ipo?.IPOStatus === "AllotmentOut"
                                              ? " Allotment Out"
                                              : null}
                                            {ipo?.IPOStatus === "AllotmentToday"
                                              ? "Allotment Today"
                                              : null}
                                            {ipo?.IPOStatus === "Live"
                                              ? "Live"
                                              : null}
                                            {ipo?.IPOStatus === "Upcoming"
                                              ? "Upcoming"
                                              : null}
                                            {ipo?.IPOStatus === "Listed"
                                              ? "Listed"
                                              : null}
                                          </span>
                                          <Row className="align-items-center">
                                            <Col xs={4}>
                                              <img
                                                src={ipo.file}
                                                alt={ipo.companyName}
                                                className="img-fluid ipo_company_logo"
                                              />
                                            </Col>
                                            <Col xs={8}>
                                              <h6 className="mb-0 ipo_companyName">
                                                {ipo.companyName}
                                              </h6>
                                            </Col>
                                          </Row>
                                          <Row className="top_border">
                                            <Col
                                              xs={8}
                                              className="border_right"
                                            >
                                              <p className="small card_title mb-0">
                                                Offer Date
                                              </p>
                                              <p className="mb-0 card_info fw-medium">
                                                {formatDateRange(
                                                  ipo.IPOOpenDate,
                                                  ipo.IPOCloseDate
                                                )}
                                              </p>
                                            </Col>
                                            <Col xs={4}>
                                              <p className="small card_title mb-0">
                                                Offer Price
                                              </p>
                                              <p className="mb-0 card_info fw-medium">
                                                ₹{ipo.fromPrice} to ₹
                                                {ipo.toPrice}
                                              </p>
                                            </Col>
                                          </Row>
                                          <Row className="top_border">
                                            <Col
                                              xs={7}
                                              className="border_right"
                                            >
                                              <p className="small card_title mb-0">
                                                Listing Date
                                              </p>
                                              <p className="mb-0 card_info fw-medium">
                                                {formatDate(ipo?.listingDate)}
                                              </p>
                                            </Col>
                                            <Col xs={5}>
                                              <p className="small card_title mb-0">
                                                Listing Price
                                              </p>
                                              <strong
                                                className={
                                                  ipo?.listingPrice -
                                                    ipo?.toPrice <
                                                  0
                                                    ? "listingPricefalse"
                                                    : "listingPricetrue"
                                                }
                                              >
                                                ₹{ipo?.listingPrice} (
                                                {(
                                                  (ipo?.listingPrice * 100) /
                                                    ipo?.toPrice -
                                                  100
                                                ).toFixed(2)}
                                                %)
                                              </strong>
                                            </Col>
                                          </Row>
                                          <Row className="top_border">
                                            <Col>
                                              <p className="ipo_card_link mb-0">
                                                View More Information
                                              </p>
                                            </Col>
                                          </Row>
                                        </Link>
                                      ))}
                                    </div>
                                  </>
                                )}
                              </>
                            )}
                          </div>
                          <div className="text-center mt-3">
                            <Link
                              to="/mainboard-listed-ipos"
                              className="btn btn-ipo viewall_btn"
                            >
                              View All Mainboard Listed IPOs
                            </Link>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="listed-sme">
                          <div className="d-none d-md-block">
                            {loading ? (
                              <div className="text-center">
                                <Loader />
                              </div>
                            ) : (
                              <Table bordered className="ipo_table">
                                {listedSMEIpos.length === 0 ? (
                                  <div>
                                    <center>
                                      <img
                                        src={nodata}
                                        class="img-fluid"
                                        alt="sectionbg"
                                      />
                                    </center>
                                  </div>
                                ) : (
                                  <>
                                    <thead>
                                      <tr>
                                        <th colSpan="2">IPO Name</th>
                                        <th>Offer Date</th>
                                        <th>Offer Price</th>
                                        <th>Lot Size</th>
                                        <th>Subs</th>
                                        <th colSpan="2">GMP</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {listedSMEIpos.map((ipo, index) => (
                                        <tr key={ipo.id}>
                                          <td className="ipo-image">
                                            <img
                                              src={ipo.file}
                                              alt={ipo.companyName}
                                            />
                                          </td>
                                          <td className="ipo-name">
                                            <span
                                              className={`status ${ipo.IPOStatus.toLowerCase()}`}
                                            >
                                              {ipo?.IPOStatus ===
                                              "WaitingAllotment"
                                                ? "Waiting Allotment"
                                                : null}
                                              {ipo?.IPOStatus === "AllotmentOut"
                                                ? " Allotment Out"
                                                : null}
                                              {ipo?.IPOStatus ===
                                              "AllotmentToday"
                                                ? "Allotment Today"
                                                : null}
                                              {ipo?.IPOStatus === "Live"
                                                ? "Live"
                                                : null}
                                              {ipo?.IPOStatus === "Upcoming"
                                                ? "Upcoming"
                                                : null}
                                              {ipo?.IPOStatus === "Listed"
                                                ? "Listed"
                                                : null}
                                            </span>
                                            <Link
                                              className="ipo_name btn btn-primary"
                                              to={`/ipo/${ipo.slug}`}
                                            >
                                              <strong>{ipo.companyName}</strong>
                                            </Link>
                                          </td>
                                          <td className="ipo-date">
                                            {formatDateRange(
                                              ipo.IPOOpenDate,
                                              ipo.IPOCloseDate
                                            )}
                                          </td>
                                          <td className="ipo-price">
                                            ₹{ipo.fromPrice} to ₹{ipo.toPrice}
                                          </td>
                                          <td className="ipo-lot">
                                            {ipo.lotSize} Shares
                                          </td>
                                          <td className="ipo-subs">
                                            {ipo.total} x
                                          </td>
                                          <td
                                            className={`ipo-gmp ${
                                              ipo.GMP < 0
                                                ? "false"
                                                : ipo.GMP > 0
                                                ? "true"
                                                : "neutral"
                                            }`}
                                          >
                                            {isNaN(ipo.GMP)
                                              ? "-"
                                              : `₹${ipo.GMP}`}{" "}
                                            {isNaN(ipo.GMP) ||
                                            isNaN(ipo.toPrice)
                                              ? ""
                                              : `(${(
                                                  (ipo.GMP * 100) /
                                                  ipo.toPrice
                                                ).toFixed(2)}%)`}
                                          </td>
                                          <td className="ipo-link">
                                            <Link
                                              className="btn btn-primary"
                                              to={`/ipo/${ipo.slug}`}
                                            >
                                              View
                                            </Link>
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </>
                                )}
                              </Table>
                            )}
                          </div>
                          <div className="d-block d-md-none">
                            {loading ? (
                              <div className="text-center">
                                <Loader />
                              </div>
                            ) : (
                              <>
                                {listedSMEIpos.length === 0 ? (
                                  <div>
                                    <center>
                                      <img
                                        src={nodata}
                                        class="img-fluid"
                                        alt="sectionbg"
                                      />
                                    </center>
                                  </div>
                                ) : (
                                  <>
                                    <div className="mobile_ipo text-start">
                                      {listedSMEIpos.map((ipo, index) => (
                                        <Link
                                          className="mobile_card"
                                          to={`/ipo/${ipo.slug}`}
                                          key={ipo.id}
                                        >
                                          <span
                                            className={`status ${ipo.IPOStatus.toLowerCase()}`}
                                          >
                                            {ipo?.IPOStatus ===
                                            "WaitingAllotment"
                                              ? "Waiting Allotment"
                                              : null}
                                            {ipo?.IPOStatus === "AllotmentOut"
                                              ? " Allotment Out"
                                              : null}
                                            {ipo?.IPOStatus === "AllotmentToday"
                                              ? "Allotment Today"
                                              : null}
                                            {ipo?.IPOStatus === "Live"
                                              ? "Live"
                                              : null}
                                            {ipo?.IPOStatus === "Upcoming"
                                              ? "Upcoming"
                                              : null}
                                            {ipo?.IPOStatus === "Listed"
                                              ? "Listed"
                                              : null}
                                          </span>
                                          <Row className="align-items-center">
                                            <Col xs={4}>
                                              <img
                                                src={ipo.file}
                                                alt={ipo.companyName}
                                                className="img-fluid ipo_company_logo"
                                              />
                                            </Col>
                                            <Col xs={8}>
                                              <h6 className="mb-0 ipo_companyName">
                                                {ipo.companyName}
                                              </h6>
                                            </Col>
                                          </Row>
                                          <Row className="top_border">
                                            <Col
                                              xs={8}
                                              className="border_right"
                                            >
                                              <p className="small card_title mb-0">
                                                Offer Date
                                              </p>
                                              <p className="mb-0 card_info fw-medium">
                                                {formatDateRange(
                                                  ipo.IPOOpenDate,
                                                  ipo.IPOCloseDate
                                                )}
                                              </p>
                                            </Col>
                                            <Col xs={4}>
                                              <p className="small card_title mb-0">
                                                Offer Price
                                              </p>
                                              <p className="mb-0 card_info fw-medium">
                                                ₹{ipo.fromPrice} to ₹
                                                {ipo.toPrice}
                                              </p>
                                            </Col>
                                          </Row>
                                          <Row className="top_border">
                                            <Col
                                              xs={7}
                                              className="border_right"
                                            >
                                              <p className="small card_title mb-0">
                                                Listing Date
                                              </p>
                                              <p className="mb-0 card_info fw-medium">
                                                {formatDate(ipo?.listingDate)}
                                              </p>
                                            </Col>
                                            <Col xs={5}>
                                              <p className="small card_title mb-0">
                                                Listing Price
                                              </p>
                                              <strong
                                                className={
                                                  ipo?.listingPrice -
                                                    ipo?.toPrice <
                                                  0
                                                    ? "listingPricefalse"
                                                    : "listingPricetrue"
                                                }
                                              >
                                                ₹{ipo?.listingPrice} (
                                                {(
                                                  (ipo?.listingPrice * 100) /
                                                    ipo?.toPrice -
                                                  100
                                                ).toFixed(2)}
                                                %)
                                              </strong>
                                            </Col>
                                          </Row>
                                          <Row className="top_border">
                                            <Col>
                                              <p className="ipo_card_link mb-0">
                                                View More Information
                                              </p>
                                            </Col>
                                          </Row>
                                        </Link>
                                      ))}
                                    </div>
                                  </>
                                )}
                              </>
                            )}
                          </div>
                          <div className="text-center mt-3">
                            <Link
                              to="/sme-listed-ipos"
                              className="btn btn-ipo viewall_btn"
                            >
                              View All SME Listed IPOs
                            </Link>
                          </div>
                        </Tab.Pane>
                      </Tab.Content>
                    </Tab.Container>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </div>
          </Tab.Container>
        </div>
      </section>
      <section className="row_am features_section" id="features">
        <div className="feature_section_bg">
          <img src={sectionbg} alt="sectionbg" />
        </div>
        <Container>
          <div className="features_inner">
            <div className="feature_img">
              <img src={devicefeature} alt="devicefeature" />
            </div>
            <div className="section_title">
              <h2>
                <span>Features</span> that make the app different!
              </h2>
              <p>
                Features are specific characteristics or capabilities of a
                product or service that distinguish it from competitors and meet
                customer needs.
              </p>
            </div>
            <div className="features_block">
              <Row>
                <Col md={3}>
                  <div className="feature_box">
                    <div className="image">
                      <img src={uidesign} alt="uidesign" />
                    </div>
                    <div className="text">
                      <h4>Attractive UI design</h4>
                      <p>
                        Concise and user-friendly UI design will help you to
                        easily track live IPO.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col md={3}>
                  <div className="feature_box">
                    <div className="image">
                      <img src={information} alt="information" />
                    </div>
                    <div className="text">
                      <h4>IPO Information</h4>
                      <p>Get all initial information about IPOs in India.</p>
                    </div>
                  </div>
                </Col>
                <Col md={3}>
                  <div className="feature_box">
                    <div className="image">
                      <img src={subscription} alt="subscription" />
                    </div>
                    <div className="text">
                      <h4>Live Subscription</h4>
                      <p>
                        Check live subscription status of the Mainline IPOs.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col md={3}>
                  <div className="feature_box">
                    <div className="image">
                      <img src={allotment} alt="allotment" />
                    </div>
                    <div className="text">
                      <h4>Allotment Prediction</h4>
                      <p>
                        With subscription tracking of IPO you can now know what
                        are the chances of allotment.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col md={3}>
                  <div className="feature_box">
                    <div className="image">
                      <img src={prediction} alt="prediction" />
                    </div>
                    <div className="text">
                      <h4>Track List Price</h4>
                      <p>
                        You can check the IPO allotment status from IPO
                        registrar company.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col md={3}>
                  <div className="feature_box">
                    <div className="image">
                      <img src={listprice} alt="listprice" />
                    </div>
                    <div className="text">
                      <h4>Track List Price</h4>
                      <p>You can track the listing price( BSE/NSE) of IPO.</p>
                    </div>
                  </div>
                </Col>
                <Col md={3}>
                  <div className="feature_box">
                    <div className="image">
                      <img src={notification} alt="notification" />
                    </div>
                    <div className="text">
                      <h4>Notification</h4>
                      <p>Get Notified whenever a New IPO is added.</p>
                    </div>
                  </div>
                </Col>
                <Col md={3}>
                  <div className="feature_box">
                    <div className="image">
                      <img src={messaging} alt="messaging" />
                    </div>
                    <div className="text">
                      <h4>Messaging Feature</h4>
                      <p>
                        The Messaging Feature of our app takes you can discuss
                        IPO and get guidance from experts.
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </section>
      <section className="row_am about_app_section" id="about_app">
        <Container>
          <div className="row">
            <Col lg={6}>
              <div className="about_img">
                <div className="frame_img">
                  <img
                    className="moving_position_animatin"
                    src={aboutframe}
                    alt="aboutframe"
                  />
                </div>
                <div className="screen_img">
                  <img
                    className="moving_animation"
                    src={aboutscreen}
                    alt="aboutscreen"
                  />
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <div className="about_text">
                <div className="section_title">
                  <h2>
                    Some awesome words <span>about app.</span>
                  </h2>
                  <p>
                    IPO Dekho gives all IPO news, details, information, alerts,
                    and Live subscriptions which may be helpful to people who
                    are interested to invest in the market and initial public
                    offering (IPO), but not able to invest due to lack of
                    information.
                  </p>
                </div>
                <ul className="app_statstic" id="counter">
                  <li>
                    <div className="icon">
                      <img src={download} alt="download" />
                    </div>
                    <div className="text">
                      <p>
                        <span className="counter-value" data-count="21">
                          0
                        </span>
                        <span>K +</span>
                      </p>
                      <p>Download</p>
                    </div>
                  </li>
                  <li>
                    <div className="icon">
                      <img src={followers} alt="followers" />
                    </div>
                    <div className="text">
                      <p>
                        <span className="counter-value" data-count="2">
                          0
                        </span>
                        <span>K +</span>
                      </p>
                      <p>Followers</p>
                    </div>
                  </li>
                  <li>
                    <div className="icon">
                      <img src={reviews} alt="reviews" />
                    </div>
                    <div className="text">
                      <p>
                        <span className="counter-value" data-count="150">
                          0
                        </span>
                        <span>+</span>
                      </p>
                      <p>Reviews</p>
                    </div>
                  </li>
                  <li>
                    <div className="icon">
                      <img src={countries} alt="countries" />
                    </div>
                    <div className="text">
                      <p>
                        <span className="counter-value" data-count="150">
                          0
                        </span>
                        <span>+</span>
                      </p>
                      <p>Countries</p>
                    </div>
                  </li>
                </ul>
              </div>
            </Col>
          </div>
        </Container>
      </section>
      <TestimonialSection />
      <FreeAppSection />
      <InterfaceSection />
    </>
  );
};
export default HomePage;
