// src/Page/MainboardLive.js

import React, { useState, useEffect } from "react";
import { Table, Row, Col } from "react-bootstrap";
import axios from "axios";
import { Helmet } from "react-helmet";
import Loader from "../Component/Loader";
import nodata from "../asset/images/ListingSoon.webp";
import { Link } from "react-router-dom";
import MainboardLive_Fetureimg from "../asset/images/MainboardLive_Fetureimg.jpg";


const MainboardLive = () => {
  const [loading, setLoading] = useState(true);
  const [liveMainLineIpos, setliveMainLineIpos] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Start loading
      try {
        const mainLineLiveResponse = await axios.post(
          "https://app.ipodekho.com/GetMainLineIpo",
          {
            CategoryForIPOS: "MainlineIPO",
            type: "Live",
          }
        );
        setliveMainLineIpos(mainLineLiveResponse.data.liveIpo);
        setLoading(false); // Stop loading
      } catch (error) {
        console.error("Error fetching IPO data:", error);
        setLoading(false); // Stop loading even if there is an error
      }
    };
    fetchData();
  }, []);
  function formatDateRange(openDate, closeDate) {
    const options = { day: "numeric", month: "short", year: "numeric" };
    const open = new Date(openDate).toLocaleDateString(undefined, options);
    const close = new Date(closeDate).toLocaleDateString(undefined, options);
    return `${open} to ${close}`;
  }

  return (
    <>
      <Helmet>
        <title>Mainboard Live IPOs - IPO DEKHO</title>
        <meta name="description" content="Welcome to the Mainboard Live IPOs section on IPO DEKHO, your go-to platform for the latest updates and information on active initial public offerings. This page provides comprehensive details on all current IPOs listed on the mainboard, helping investors make informed decisions." />
        <meta property="og:url" content="https://ipodekho.com/mainboard-live-ipos" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Mainboard Live IPOs - IPO DEKHO" />
        <meta property="og:description" content="Welcome to the Mainboard Live IPOs section on IPO DEKHO, your go-to platform for the latest updates and information on active initial public offerings. This page provides comprehensive details on all current IPOs listed on the mainboard, helping investors make informed decisions." />
        <meta property="og:image" content={MainboardLive_Fetureimg} />

        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="https://ipodekho.com/mainboard-live-ipos" />
        <meta property="twitter:url" content="https://ipodekho.com/mainboard-live-ipos" />
        <meta name="twitter:title" content="Mainboard Live IPOs - IPO DEKHO" />
        <meta name="twitter:description" content="Welcome to the Mainboard Live IPOs section on IPO DEKHO, your go-to platform for the latest updates and information on active initial public offerings. This page provides comprehensive details on all current IPOs listed on the mainboard, helping investors make informed decisions." />
        <meta name="twitter:image" content={MainboardLive_Fetureimg} />
      </Helmet>
      <section className="ipo-detail">
        <div className="container">
          <div className="section_title">
            <h2>
              <span>Mainboard Live </span> IPOs
            </h2>
            <p>
              Mainboard live IPOs feature established companies actively listing
              their shares on major stock exchanges. Investors closely monitor
              these live offerings to purchase shares at their initial price.
              These IPOs typically involve well-known firms with robust
              financials, presenting opportunities for significant market impact
              and promising long-term returns.
            </p>
          </div>
          <div className="ipo_table_div">
            <div className="d-none d-md-block">
              {loading ? (
                <div className="text-center">
                  <Loader />
                </div>
              ) : (
                <Table bordered className="ipo_table">
                  {liveMainLineIpos.length === 0 ? (
                    <div>
                      <center>
                        <img src={nodata} class="img-fluid" alt="sectionbg" />
                      </center>
                    </div>
                  ) : (
                    <>
                      <thead>
                        <tr>
                          <th colSpan="2">IPO Name</th>
                          <th>Offer Date</th>
                          <th>Offer Price</th>
                          <th>Lot Size</th>
                          <th>Subs</th>
                          <th colSpan="2">GMP</th>
                        </tr>
                      </thead>
                      <tbody>
                        {liveMainLineIpos.map((ipo, index) => (
                          <tr key={ipo.id}>
                            <td className="ipo-image">
                              <img src={ipo.file} alt={ipo.companyName} />
                            </td>
                            <td className="ipo-name">
                              <span
                                className={`status ${ipo.IPOStatus.toLowerCase()}`}
                              >
                                {ipo?.IPOStatus === "WaitingAllotment"
                                  ? "Waiting Allotment"
                                  : null}
                                {ipo?.IPOStatus === "AllotmentOut"
                                  ? " Allotment Out"
                                  : null}
                                {ipo?.IPOStatus === "AllotmentToday"
                                  ? "Allotment Today"
                                  : null}
                                {ipo?.IPOStatus === "Live" ? "Live" : null}
                                {ipo?.IPOStatus === "Upcoming"
                                  ? "Upcoming"
                                  : null}
                                {ipo?.IPOStatus === "Listed" ? "Listed" : null}
                              </span>
                              <Link
                                className="ipo_name btn btn-primary"
                                to={`/ipo/${ipo.slug}`}
                              >
                                <strong>{ipo.companyName}</strong>
                              </Link>
                            </td>
                            <td className="ipo-date">
                              {formatDateRange(
                                ipo.IPOOpenDate,
                                ipo.IPOCloseDate
                              )}
                            </td>
                            <td className="ipo-price">
                              ₹{ipo.fromPrice} to ₹{ipo.toPrice}
                            </td>
                            <td className="ipo-lot">{ipo.lotSize} Shares</td>
                            <td className="ipo-subs">{ipo.total} x</td>
                            <td
                              className={`ipo-gmp ${
                                ipo.GMP < 0
                                  ? "false"
                                  : ipo.GMP > 0
                                  ? "true"
                                  : "neutral"
                              }`}
                            >
                              {isNaN(ipo.GMP) ? "-" : `₹${ipo.GMP}`}{" "}
                              {isNaN(ipo.GMP) || isNaN(ipo.toPrice)
                                ? ""
                                : `(${((ipo.GMP * 100) / ipo.toPrice).toFixed(
                                    2
                                  )}%)`}
                            </td>
                            <td className="ipo-link">
                              <Link className="btn btn-primary" to={`/ipo/${ipo.slug}`}>
                                            View
                                          </Link>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </>
                  )}
                </Table>
              )}
            </div>
            <div className="d-block d-md-none">
              {loading ? (
                <div className="text-center">
                  <Loader />
                </div>
              ) : (
                <>
                  {liveMainLineIpos.length === 0 ? (
                    <div>
                      <center>
                        <img src={nodata} class="img-fluid" alt="sectionbg" />
                      </center>
                    </div>
                  ) : (
                    <>
                      <div className="mobile_ipo text-start">
                        {liveMainLineIpos.map((ipo, index) => (
                          <Link
                            className="mobile_card"
                            to={`/ipo/${ipo.slug}`}
                            key={ipo.id}
                          >
                            <span
                              className={`status ${ipo.IPOStatus.toLowerCase()}`}
                            >
                              {ipo?.IPOStatus === "WaitingAllotment"
                                ? "Waiting Allotment"
                                : null}
                              {ipo?.IPOStatus === "AllotmentOut"
                                ? " Allotment Out"
                                : null}
                              {ipo?.IPOStatus === "AllotmentToday"
                                ? "Allotment Today"
                                : null}
                              {ipo?.IPOStatus === "Live" ? "Live" : null}
                              {ipo?.IPOStatus === "Upcoming"
                                ? "Upcoming"
                                : null}
                              {ipo?.IPOStatus === "Listed" ? "Listed" : null}
                            </span>
                            <Row className="align-items-center">
                              <Col xs={4}>
                                <img
                                  src={ipo.file}
                                  alt={ipo.companyName}
                                  className="img-fluid ipo_company_logo"
                                />
                              </Col>
                              <Col xs={8}>
                                <h6 className="mb-0 ipo_companyName">
                                  {ipo.companyName}
                                </h6>
                              </Col>
                            </Row>
                            <Row className="top_border">
                              <Col xs={8} className="border_right">
                                <p className="small card_title mb-0">
                                  Offer Date
                                </p>
                                <p className="mb-0 card_info fw-medium">
                                  {formatDateRange(
                                    ipo.IPOOpenDate,
                                    ipo.IPOCloseDate
                                  )}
                                </p>
                              </Col>
                              <Col xs={4}>
                                <p className="small card_title mb-0">
                                  Offer Price
                                </p>
                                <p className="mb-0 card_info fw-medium">
                                  ₹{ipo.fromPrice} to ₹{ipo.toPrice}
                                </p>
                              </Col>
                            </Row>
                            <Row className="top_border">
                              <Col xs={4} className="border_right">
                                <p className="small card_title mb-0">
                                  Lot Size
                                </p>
                                <p className="mb-0 card_info fw-medium">
                                  {ipo.lotSize} Shares
                                </p>
                              </Col>
                              <Col xs={3} className="border_right">
                                <p className="small card_title mb-0">Subs</p>
                                <p className="mb-0 card_info fw-medium">
                                  {ipo.total} x
                                </p>
                              </Col>
                              <Col xs={5}>
                                <p className="small card_title mb-0">
                                  Premium (GMP)
                                </p>
                                <p
                                  className={`mb-0 ipo-gmp ${
                                    ipo.GMP < 0
                                      ? "false"
                                      : ipo.GMP > 0
                                      ? "true"
                                      : "neutral"
                                  }`}
                                >
                                  {isNaN(ipo.GMP) ? "-" : `₹${ipo.GMP}`}{" "}
                                  {isNaN(ipo.GMP) || isNaN(ipo.toPrice)
                                    ? ""
                                    : `(${(
                                        (ipo.GMP * 100) /
                                        ipo.toPrice
                                      ).toFixed(2)}%)`}
                                </p>
                              </Col>
                            </Row>
                            <Row className="top_border">
                              <Col>
                                <p className="ipo_card_link mb-0">
                                  View More Information
                                </p>
                              </Col>
                            </Row>
                          </Link>
                        ))}
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MainboardLive;
