import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const TermsConditions = () => {
  return (
    <section className="banner_section">
      <Container>
        <Row>
          <Col lg={12}>
            <h1 className="text-center fw-bold mb-5">Terms Conditions</h1>
            <h2>Access to the App</h2>
            <h5>Subject to these Terms.</h5>
            <p>
              The company provides you with a limited license that is
              non-transferable, non-exclusive, and revocable. This license
              permits you to access the App solely for your personal and
              non-commercial use.
            </p>
            <h5>Certain Restrictions.</h5>
            <p>
              These Terms impose certain restrictions on the rights granted to
              you, which include: (a) refraining from engaging in any commercial
              exploitation of the App, such as selling, renting, leasing,
              transferring, assigning, distributing, or hosting it; (b)
              refraining from modifying, creating derivative works of,
              disassembling, reverse compiling, or reverse engineering any part
              of the App; (c) refraining from accessing the App for the purpose
              of developing a similar or competing App; and (d) unless otherwise
              indicated, refraining from copying, reproducing, distributing,
              republishing, downloading, displaying, posting, or transmitting
              any part of the App. Additionally, any future updates, releases,
              or other changes to the App's functionality will also be subject
              to these Terms. You must retain all copyright and proprietary
              notices on any copies of the App.
            </p>
            <p>
              The Company reserves the right to modify, suspend, or terminate
              the App with or without prior notice to you. By agreeing to these
              Terms, you acknowledge and accept that the Company will not be
              liable to you or any third-party for any changes, interruptions,
              or termination of the App or any part thereof.
            </p>
            <h5>No Support or Maintenance.</h5>
            <p>
              Company is not obligated to offer any support for the App. All
              intellectual property rights, including patents, copyrights, and
              trademarks, belong to Company or its suppliers, and you do not
              have any rights or interests in them except for limited access
              rights under Section 2.1 of the Terms. Company and its suppliers
              retain all other rights.
            </p>
            <h2>
              <strong>Third-Party Links &amp; Ads; Other Users</strong>
            </h2>
            <h5>Third-Party Links &amp; Ads.&nbsp;</h5>
            <p>
              The App may feature advertisements and links to third-party Apps
              and services. These Third-Party Links &amp; Ads are not controlled
              by Company, and therefore, Company cannot be held responsible for
              them. Company provides access to these Third-Party Links &amp; Ads
              solely for your convenience and does not endorse, monitor,
              approve, warrant, or make any representations about them. You use
              them at your own risk and should exercise caution and discretion.
              By clicking on any of the Third-Party Links &amp; Ads, you will be
              subject to the third-party's policies and terms, including their
              data gathering and privacy practices
            </p>
            <h5>Other Users</h5>
            <p>
              All users are solely responsible for their User Content, and
              Company is not liable for any loss or damage resulting from
              interactions with other users or User Content. Company is not
              obligated to mediate disputes between users. By using the App, you
              waive any present or future claims against Company and its
              officers, employees, agents, successors, and assigns relating to
              the App. California residents also waive civil code section 1542
              regarding unknown claims.
            </p>
            <h5>Google DoubleClick DART Cookie</h5>
            <p>
              Google, a third-party vendor, uses DART cookies to display ads on
              our App and other Apps on the internet. These cookies use visitor
              data to serve ads based on their browsing history. Visitors can
              choose to opt-out of the use of DART cookies by visiting the
              Google ad and content network Privacy Policy at the following URL:
              <a
                href="https://policies.google.com/technologies/ads"
                rel="noopener noreferrer"
                target="_blank"
              >
                {" "}
                https://policies.google.com/technologies/ads
              </a>
              .
            </p>
            <h5>Our Advertising Partners.&nbsp;</h5>
            <p>
              Our App's advertisers may use cookies and web beacons. For your
              convenience, we have provided hyperlinks to the Privacy Policies
              of our advertising partners below. Please note that each partner
              has their own policies regarding user data.
            </p>
            <p>
              Our advertising partner, Google, has its own Privacy Policy which
              can be accessed at the following URL:
              <a
                href="https://policies.google.com/technologies/ads"
                rel="noopener noreferrer"
                target="_blank"
              >
                https://policies.google.com/technologies/ads
              </a>
              .
            </p>
            <h2>Disclaimers</h2>
            <p>
              The App is provided "as-is" and "as available" and Company and its
              suppliers disclaim all warranties and conditions, whether express,
              implied or statutory, including merchantability, fitness for a
              particular purpose, title, quiet enjoyment, accuracy or
              non-infringement. Company and its suppliers do not guarantee
              uninterrupted, timely, secure or error-free access to the App or
              that it will be accurate, reliable, free of viruses or other
              harmful code, complete, legal or safe. Any applicable warranties
              are limited to ninety (90) days. Some jurisdictions may not allow
              such exclusions or limitations.
            </p>
            <h2>Limitation on Liability</h2>
            <p>
              In accordance with the applicable law, Company and its suppliers
              shall not be liable to you or any third party for any indirect,
              consequential, exemplary, incidental, special or punitive damages,
              including but not limited to lost profits, lost data, or costs of
              procurement of substitute products, arising out of or related to
              these Terms or your use of, or inability to use, the App, even if
              Company has been advised of the possibility of such damages. Your
              access to and use of the App is at your own risk, and you are
              solely responsible for any damage to your device or computer
              system, or loss of data resulting therefrom.
            </p>
            <p>
              We will not be liable for any damages arising from or related to
              this agreement, except as permitted by law, and our liability will
              always be limited to a maximum of fifty U.S. dollars (U.S. $50).
              This limit will not be enlarged by the existence of more than one
              claim. Our suppliers will have no liability of any kind arising
              from or relating to this agreement, and you agree to this
              provision. However, some jurisdictions do not allow the limitation
              or exclusion of liability for incidental or consequential damages,
              so the above limitation or exclusion may not apply to you.
            </p>
            <h5>Term and Termination</h5>
            <p>
              Provided that you comply with these Terms, they shall remain in
              effect throughout your use of the App. However, we reserve the
              right to terminate or suspend your access to the App at any time,
              at our sole discretion, if we determine that you have violated
              these Terms or for any other reason. Upon termination of your
              access, your Account and associated User Content may be deleted
              from our live databases without any liability to you on our part.
              Notwithstanding any termination, the provisions outlined in
              Sections 2 through 2.5, Section 3, and Sections 4 through 10 shall
              continue to apply.
            </p>
            <h2>Copyright Policy</h2>
            <p>
              IPODEKHO.in respects the intellectual property rights of others
              and expects its users to do the same. We take copyright
              infringement very seriously and will respond to any claims of
              infringement that are properly reported to us.
            </p>
            <p>
              If you believe that your work has been copied in a way that
              constitutes copyright infringement, please provide us with the
              following information:
            </p>
            <ol>
              <li>
                A description of the copyrighted work that you claim has been
                infringed;
              </li>
              <li>
                The exact location on IPODEKHO where the infringing material is
                located;
              </li>
              <li>
                Your contact information including name, address, telephone
                number, and email address;
              </li>
              <li>
                A statement by you that you have a good faith belief that the
                disputed use is not authorized by the copyright owner, its
                agent, or the law;
              </li>
              <li>
                A statement by you, made under penalty of perjury, that the
                above information in your notice is accurate and that you are
                the copyright owner or authorized to act on the copyright
                owner's behalf.
              </li>
            </ol>
            <p>
              Please send your copyright infringement claim to the following
              address:
            </p>
            <p>
              Email: info@ipodekho.com Subject: Copyright Infringement Claim
            </p>
            <p>
              Upon receipt of a valid copyright infringement claim, we will take
              prompt action to remove the infringing material from IPODekho and,
              if appropriate, terminate the account of the user who posted the
              material.
            </p>
            <h2>General</h2>
            <p>
              These Terms may be revised from time to time, and if there are
              significant changes, we may notify you via email using the last
              email address you provided us or by prominently posting a notice
              of the changes on our App. It is your responsibility to provide us
              with your current email address. In case the last email address
              provided to us is not valid, the dispatch of the email containing
              such notice will still be considered effective notice of the
              described changes. These changes will be effective within thirty
              (30) calendar days following our dispatch of an email notice to
              you or thirty (30) calendar days following the posting of the
              notice on our App, whichever is earlier. For new users of our App,
              the changes will be effective immediately. Your continued use of
              our App following notice of such changes indicates your
              acknowledgement and agreement to be bound by the terms and
              conditions of the changes.
            </p>
            <p>
              Please read the Arbitration Agreement carefully as it is part of
              your contract with the Company and affects your rights. The
              Agreement includes procedures for MANDATORY BINDING ARBITRATION
              AND A CLASS ACTION WAIVER.
            </p>
            <p>
              <strong>Contact Information</strong>
            </p>
            <p>
              If you have any questions or concerns about these terms and
              conditions, please contact us at info@ipodekho.com
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default TermsConditions;
